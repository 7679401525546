import { PropertyAggregationFields } from "../aggregations";
import { BaseEntity } from "../db/baseEntity.types";
import { BooleanSelectType } from "./fieldFormat.types";
import { BaseAddress } from "./location.types";
import {
  DataSheetCellMetadata,
  PortfolioTemplate,
  PortfolioTemplateType
} from "./portfolioTemplate.types";
import {
  AppraisalCoordinatorType,
  AppraisalType,
  AppraisalValueDerivationType,
  BillingFrequencyType,
  ConditionRatingType,
  ConstructionBudgetAssessmentType,
  FloodInsurancePremiumPaymentType,
  InspectionInitialDelayReasonStatusType,
  InspectionOrderType,
  LeaseStatusType,
  LeaseStrategyType,
  PropertyInsurancePremiumPaymentType,
  PropertyLoanPurpose,
  PropertyRightsOwnershipType,
  PropertyType,
  PropertyZoningComplianceRatingType,
  QualityRatingType
} from "./property.enums.types";
import { FloodCertificateVendorType, IntegrationType } from "./task-types";

export type PropertyAddress = BaseAddress & {
  PropertyIdentifier: string;
  LegalDescription: string;
  FullAddressName: string;
  PropertyLotIdentifier: string;
  PropertyBlockIdentifier: string;
  PropertySubdivisionIdentifier: string;
};

export type Appraisal = {
  AppraisalValueDerivationType: AppraisalValueDerivationType;
  AppraisalOrderedDate: string;
  AppraisalCompletedDate: string;
  AppraisalEffectiveDate: string;
  AppraisalExpirationDate: string;
  AppraisalType: AppraisalType;
  QualityRatingType: QualityRatingType;
  ConditionRatingType: ConditionRatingType;
  TotalBedroomCount: string;
  TotalBathroomCount: string;
  PropertyStructureBuiltYear: string;
  AppraisalDate: string;
  PropertyAreaValue: string;
  PropertyBuiltYear: string;
  SpecificZoningClassification: string;
  PropertyZoningDescription: string;
  PropertyZoningComplianceRatingType: PropertyZoningComplianceRatingType;
  PropertyValuationAmount: string;
  SubjectToPropertyValuationAmount: string;
  AppraisalCoordinator: AppraisalCoordinatorType;
  InternalValuationEligibilityIndicator: BooleanSelectType;
  PropertyAccessInformation: string;
  AppraisalForm1007RequiredIndicator: BooleanSelectType;
};

export type AppraisalManagementCompany = {
  RepresentativeFullName: string;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
  AppraisalManagementCompanyName: string;
};

export type FloodInsuranceAgency = BaseAddress & {
  FullName: string;
  RepresentativeFullName: string;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
};

export type FloodInsurance = {
  DwellingCoverageAmount: string;
  InsurancePremiumPaymentType: FloodInsurancePremiumPaymentType;
  InsurancePremiumAmount: string;
  InsurancePremiumOutstandingAmount: string;
  DeductibleAmount: string;
  PolicyNumber: string;
  PolicyExpirationDate: string;
  NextPaymentDueDate: string;
};

export type PropertyInsuranceAgency = BaseAddress & {
  FullName: string;
  InsuranceAgentFullName: string;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
};

export type PropertyInsurance = {
  PropertyInsurancePolicyIdentifier: string;
  DeductibleAmount: string;
  InsurancePremiumPaymentType: PropertyInsurancePremiumPaymentType;
  InsurancePremiumAmount: string;
  InsurancePremiumOutstandingAmount: string;
  DwellingCoverageAmount: string;
  RentalLossCoverageAmount: string;
  LiabilityCoverageAmount: string;
  ReplacementCoverageIndicator: string;
  PolicyExpirationDate: string;
  NextPaymentDueDate: string;
  BuilderRiskInsurance: string;
};

/** @deprecated
 * Use the customer-specific 'LeasedFinancedUnit' type instead.
 */
export type LeasedFinancedUnit = {
  UnitName: string;
  MonthlyLeaseRentAmount: string;
  MonthlyMarketRentAmount: string;
  AdjustedRentAmount: string;
  LeaseStatusType: LeaseStatusType;
  LeaseExpirationDate: string;
  RentReportConfidenceScore: string;
  PaymentVerificationIndicator: BooleanSelectType;
  CorporateLeaseIndicator: BooleanSelectType;
};

export type LeasedFinancedUnits = {
  unit0: LeasedFinancedUnit;
  unit1: LeasedFinancedUnit;
  unit2: LeasedFinancedUnit;
  unit3: LeasedFinancedUnit;
  unit4: LeasedFinancedUnit;
  unit5: LeasedFinancedUnit;
  unit6: LeasedFinancedUnit;
  unit7: LeasedFinancedUnit;
  unit8: LeasedFinancedUnit;
};

export type SheetMetadata = {
  type: PortfolioTemplateType;
};

export type PropertyInspection = {
  InspectionInitialRequestDate: string;
  InspectionRequestOrderedDate: string;
  InspectionReportDueDate: string;
  InspectionOrderType: InspectionOrderType;
  InspectorFullName: string;
  ConstructionAnalystFullName: string;
  InspectionOrderReceivedDate: string;
  InspectionOrderProcessedDate: string;
  InspectionDocumentsReceivedDate: string;
  InspectionInitialDelayReasonStatusType: InspectionInitialDelayReasonStatusType[];
};

export type PropertyFields = {
  aggregations: PropertyAggregationFields;
  dealRelation?: string[];
  Address: PropertyAddress;
  Appraisal: Appraisal;
  AppraisalManagementCompany: AppraisalManagementCompany;
  FloodInsuranceAgency: FloodInsuranceAgency;
  FloodInsurance: FloodInsurance;
  PropertyInsuranceAgency: PropertyInsuranceAgency;
  PropertyInsurance: PropertyInsurance;
  PropertyType: PropertyType;
  PropertyInspection: PropertyInspection;
  CondoWarrantableIndicator: string;
  CondoCertificateFeesCollectedIndicator: BooleanSelectType;
  CondoCertificateComments: string;
  FinancedUnitCount: string;
  PropertyLoanPurpose: PropertyLoanPurpose;
  PurchasePriceAmount: string;
  ContractClosingDate: string;
  ContractExecutionDate: string;
  OriginalPurchaseDate: string;
  OriginalPurchasePriceAmount: string;
  OutstandingLoanPayoffAmount: string;
  LeaseStatusType: LeaseStatusType;
  LeaseExpirationDate: string;
  AnnualTaxesAmount: string;
  TaxDueDate: string;
  AnnualHomeownersAssociationFeeAmount: string;
  DeferredMaintenanceAmount: string;
  ParcelNumber: string;
  RenovationCostAmount: string;
  ConstructionCostAmount: string;
  ConstructionCostComments: string;
  LTARVAmount: string;
  NetFundAmount: string;
  NetFundRatePercent: string;
  AllocatedRDSRRatePercent: string;
  PropertyRightsOwnershipType: PropertyRightsOwnershipType;
  CEMARefinanceIndicator: BooleanSelectType;
  LeasedFinancedUnits: LeasedFinancedUnits;
  RentReportConfidenceScorePercent: string;
  TotalRentalIncomeAmount: string;
  CountyAnnualTaxAmount: string;
  CountyTaxDueDate: string;
  CountyTaxBillingFrequencyType: BillingFrequencyType;
  CountyTaxingAuthority: string;
  CityAnnualTaxAmount: string;
  CityTaxDueDate: string;
  CityTaxBillingFrequencyType: BillingFrequencyType;
  CityTaxingAuthority: string;
  SpecialAssessmentAnnualTaxAmount: string;
  SpecialAssessmentTaxDueDate: string;
  SpecialAssessmentTaxBillingFrequencyType: BillingFrequencyType;
  SpecialAssessmentTaxingAuthority: string;
  CapitalExpenditureAmount: string;
  PropertyDetailComments: string;
  PSASignedIndicator: BooleanSelectType;
  PSAExtensionRequestedIndicator: BooleanSelectType;
  PSAExtensionFormIndicator: BooleanSelectType;
  PSAAssignmentContractIndicator: BooleanSelectType;
  AssignmentFeeAmount: string;
  PSAAddendumIndicator: BooleanSelectType;
  PSASellerOwnerRecordMismatchIndicator: BooleanSelectType;
  PSABuyerBorrowerMismatchIndicator: BooleanSelectType;
  PSAExpirationDate: string;
  PayoffExpirationDate: string;
  MortgageeFullName: string;
  MortgageeContactFullName: string;
  MortgageeContactPointTelephoneValue: string;
  MortgageeContactPointEmailValue: string;
  BorrowerRenovationCostsAfterPurchaseAmount: string;
  NetWireAmount: string;
  InternalL1CRefinanceIndicator: BooleanSelectType;
  LeaseStrategy: LeaseStrategyType;
  RentalLTVPercent: string;
  SheetMetadata: SheetMetadata;
  ConstructionBudgetAssessment: ConstructionBudgetAssessmentType;
  AnnualCapitalExpenditure: string;
  Integrations?: PropertyIntegrations;
  defaultInterestPaymentsOrFeesNotedIndicator: string;
};

export type DataSheetProperty<T extends PortfolioTemplate> = {
  type: PortfolioTemplateType;
} & {
  [K in keyof Omit<T, "type">]: DataSheetCellMetadata;
};

/** @deprecated
 * Use the customer-specific 'Property' type instead.
 */
export type Property = BaseEntity<PropertyFields>;

export type PropertyIntegrations = {
  [IntegrationType.FloodCertificate]: PropertyFloodCertification;
};

export type PropertyFloodCertification = {
  [FloodCertificateVendorType.ServiceLink]: PropertyFloodCertificate;
};

export type PropertyFloodCertificate = {
  LOLFlagIndicator?: string;
  CTFFlagIndicator?: string;
  FloodFlagIndicator?: string;
  UsersEmailAddress?: string;
  ProductCode?: string;
  AcceptedAcknowledgmentIndicator?: boolean;
};
