import {
  CreditReportVendorType,
  FloodCertificateVendorType,
  IntegrationType,
  PropertyReportVendorType,
  RentalDataVendorType
} from "@elphi/types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate";
import { merge } from "lodash";
import { baseSectionsLoader } from "../base/baseSectionsLoader";
import {
  getDealIntegrationsSections,
  getPropertyIntegrationsSections
} from "../integrations/sections";
import { creditReportSectionBuilders } from "../integrations/sections/credit-score/creditScore.sections";
import { SectionsLoader } from "../types/dynamicSchema.types";
import { dealSectionBuilders, propertySectionBuilders } from "./sections";
import { entityPartySectionBuilders } from "./sections/party/entityParty.sections";
import { individualPartySectionBuilders } from "./sections/party/individualParty.sections";
import { LimaSpecs } from "./types";

export const limaSectionsLoader: SectionsLoader = (r: {
  specs: LimaSpecs;
}) => ({
  loadSections: () => {
    const { specs } = r;
    const baseSections = baseSectionsLoader(r).loadSections();

    const integrations = {
      property: buildPropertyIntegrations(specs.property.entitySpecs),
      deal: buildDealIntegrations(specs.deal.entitySpecs),
      creditReport: buildCreditReportIntegration()
    };

    return {
      ...baseSections,
      deal: {
        ...baseSections.deal,
        ...dealSectionBuilders
      },
      property: {
        ...baseSections.property,
        ...propertySectionBuilders
      },
      party: {
        ...baseSections.party,
        ...merge(entityPartySectionBuilders, individualPartySectionBuilders)
      },
      integrations
    };
  }
});

const buildPropertyIntegrations = (
  propertySpecs: LimaSpecs["property"]["entitySpecs"]
) => {
  const {
    serviceLinkFloodSections,
    siteXPropertyReportSections,
    rentRangeRentalDataSections
  } = getPropertyIntegrationsSections(propertySpecs);

  return {
    [IntegrationType.FloodCertificate]: {
      [FloodCertificateVendorType.ServiceLink]: {
        ...serviceLinkFloodSections
      }
    },
    [IntegrationType.PropertyReport]: {
      [PropertyReportVendorType.SiteX]: {
        ...siteXPropertyReportSections
      }
    },
    [IntegrationType.RentalData]: {
      [RentalDataVendorType.RentRange]: {
        ...rentRangeRentalDataSections
      }
    }
  };
};

const buildDealIntegrations = (dealSpecs: LimaSpecs["deal"]["entitySpecs"]) => {
  const { FundingShieldWireInsuranceCertificateSections } =
    getDealIntegrationsSections(dealSpecs);

  return {
    [IntegrationType.WireInsuranceCertificate]: {
      [WireInsuranceVendorType.FundingShield]: {
        ...FundingShieldWireInsuranceCertificateSections
      }
    }
  };
};

const buildCreditReportIntegration = () => ({
  [IntegrationType.CreditReport]: {
    [CreditReportVendorType.Lima]: creditReportSectionBuilders
  }
});
