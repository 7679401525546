import { Box } from "@chakra-ui/react";
import { DealCalendarParty, DealPartyRelation, Party } from "@elphi/types";
import { set } from "lodash";
import { useEffect, useState } from "react";
import { useSections } from "../../../../../forms/schemas/providers/SectionsProvider";
import { useDealPartyRelationHooks } from "../../../../../hooks/dealpartyrelation.hooks";
import { usePartyFormHandler } from "../../../../../hooks/partyform.hooks";
import { DealPartyRelationForm } from "../../../../deal/DealPartyRelationForm";
import FormBuilder, {
  OnChangeInput
} from "../../../../form-builder/FormBuilder";

export type PartyEditSectionProps = {
  dealId: string;
  dealCalendarParty: DealCalendarParty;
};

export const PartyEditSection = ({
  dealId,
  dealCalendarParty
}: PartyEditSectionProps) => {
  const {
    partyId,
    dealPartyRelationId,
    partyType,
    dealPartyType,
    partyMilestone,
    locStatusType,
    relationRoleType
  } = dealCalendarParty;
  if (!partyId) return <></>;
  const { sections } = useSections();
  const [partyState, setPartyState] = useState({
    id: partyId,
    PartyMilestone: partyMilestone,
    LOCStatusType: locStatusType,
    fieldMeta: dealCalendarParty.partyFieldMeta
  });

  const [dealPartyRelationState, setDealPartyRelationState] = useState<any>({
    dealId,
    partyId,
    type: dealPartyType,
    relationRoleType,
    id: dealPartyRelationId,
    fieldMeta: dealCalendarParty.dealPartyFieldMeta
  });

  useEffect(() => {
    setPartyState((prev) => ({
      ...prev,
      PartyMilestone: partyMilestone,
      LOCStatusType: locStatusType,
      fieldMeta: dealCalendarParty.partyFieldMeta
    }));
  }, [
    partyId,
    locStatusType,
    partyMilestone,
    dealCalendarParty.partyFieldMeta
  ]);

  useEffect(() => {
    setDealPartyRelationState((prev) => ({
      ...prev,
      type: dealPartyType,
      relationRoleType,
      id: dealPartyRelationId,
      fieldMeta: dealCalendarParty.dealPartyFieldMeta
    }));
  }, [
    dealPartyType,
    relationRoleType,
    dealPartyRelationId,
    dealCalendarParty.dealPartyFieldMeta
  ]);

  const { partyOnChange } = usePartyFormHandler();
  const { updateBatch: updateDealPartyRelationBatch } =
    useDealPartyRelationHooks();

  const updateBatch = async (r: {
    relations: ({ id: string } & Partial<DealPartyRelation>)[];
  }) => {
    const relation = r.relations?.[0];

    setDealPartyRelationState((prev) => ({
      ...prev,
      ...relation
    }));
    return await updateDealPartyRelationBatch(r);
  };

  const partyOnChangeBatch = (v: OnChangeInput) => {
    const { fieldKey, value } = v;
    const fields = set({}, fieldKey, value);
    setPartyState((prev) => ({
      ...prev,
      ...fields
    }));
    partyOnChange({
      fieldType: v.fieldType,
      fieldKey: ["parties", partyId, ...v.fieldKey],
      value: v.value
    });
  };

  const section = sections?.party?.calendarPartyEditSection?.({
    state: { ...partyState, PartyType: partyType } as Partial<Party>,
    onChange: partyOnChangeBatch
  });

  return (
    <Box>
      <Box ml="-10px">
        <DealPartyRelationForm
          inputSize={{ minW: "320px" }}
          elphiView="form"
          isLoading={false}
          updateDealPartyRelationBatch={updateBatch}
          createDealParty={(_: any): any => {}}
          dealId={dealId}
          selectedDealPartyRelationState={dealPartyRelationState}
          selectedParty={partyState}
        />
      </Box>
      <Box ml="-10px">
        <FormBuilder
          customKey="partiesSection"
          onChange={partyOnChangeBatch}
          sections={[section]}
          elphiView="form"
          size={{ minW: "320px" }}
        />
      </Box>
    </Box>
  );
};
