import { Box, Flex, Progress } from "@chakra-ui/react";
import {
  AccountAsset,
  AggregationFocusType,
  Asset,
  BaseInsurancePolicy,
  CreditScore,
  Deal,
  ElphiEntityType,
  FieldStatus,
  InsurancePolicyEntityType,
  Party,
  Property,
  RolodexServiceProvider,
  ServiceProviderType,
  Statement,
  Task,
  TaskConfigurationDataToType
} from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityState } from "@reduxjs/toolkit";
import { flatten, get } from "lodash";
import { useEffect, useMemo } from "react";
import { AppConfig } from "../../../config/appConfig";
import { EMPTY } from "../../../constants/common";
import { getSpecs } from "../../../forms/schemas/factories/specsFactory";
import useAssetHooks from "../../../hooks/asset.hooks";
import { useCreditReportHooks } from "../../../hooks/creditReport.hooks";
import useDealHooks from "../../../hooks/deal.hooks";
import { useInsurancePolicyHooks } from "../../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePartyHooks } from "../../../hooks/party.hooks";
import { usePropertyHooks } from "../../../hooks/property.hooks";
import { useServiceProviderHooks } from "../../../hooks/rolodexServiceProvider.hooks";
import {
  SnapshotDataStateFormat,
  useSnapshotHooks
} from "../../../hooks/snapshot.hooks";
import useStatementHooks from "../../../hooks/statement.hooks";
import useTaskHooks from "../../../hooks/task.hooks";
import { AssetSliceState } from "../../../redux/v2/asset";
import { CreditReportSliceState } from "../../../redux/v2/credit-report";
import { InsurancePolicySliceState } from "../../../redux/v2/insurance-policy";
import { PartySliceState } from "../../../redux/v2/party";
import { PropertySliceState } from "../../../redux/v2/property";
import { ServiceProviderSliceState } from "../../../redux/v2/rolodex";
import { StatementSliceState } from "../../../redux/v2/statement";
import { getSortedData } from "../../../redux/v2/utils/sort.utils";
import { fieldTypeValidation } from "../../application/sections/formTemplate.utils";
import { AssetQuerySearch, assetQueryType } from "../../asset/AssetQuerySearch";
import {
  CreditScoreSearch,
  creditScoreQueryType
} from "../../asset/CreditScoreQuerySearch";
import FormBuilder, {
  InputBuilderSpecs,
  Section,
  SectionHeader
} from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { EntityFormFieldSpecs } from "../../form-builder/field-specs/fields.types";
import { FieldType } from "../../form-builder/fieldFormat.types";
import { additionalValidations } from "../../form-builder/formBuilder.utils";
import { InsurancePolicySearch } from "../../insurance-policy/property/wizard/search/InsurancePolicySearch";
import { DealPartySearch } from "../../party/DealPartySearch";
import { DealPropertySearchContainer } from "../../property/PropertySearch";
import { ServiceProviderSelect } from "../../rolodex/service-provider/search/ServiceProviderSearch";
import {
  StatementQuerySearch,
  statementQueryType
} from "../../statement/StatementQuerySearch";
import { useElphiToast } from "../../toast/toast.hook";
import { AttachedFieldSelectionStatusComponet } from "./AttachedFieldSelectionStatusComponent";
import {
  entityToHeader,
  fieldStatusToFieldColor,
  isAggregationPath
} from "./d2v.utils";

export type FieldSelectionInteractiveFormState = {
  deal?: { [id: string]: PartialWithId<Deal> };
  party?: { [id: string]: PartialWithId<Party> };
  property?: { [id: string]: PartialWithId<Property> };
  asset?: { [id: string]: PartialWithId<AccountAsset> };
  statement?: { [id: string]: PartialWithId<Statement> };
  "credit-score"?: { [id: string]: PartialWithId<CreditScore> };
  "service-provider"?: {
    [id: string]: PartialWithId<RolodexServiceProvider>;
  };
  "insurance-policy"?: {
    [id: string]: PartialWithId<BaseInsurancePolicy<InsurancePolicyEntityType>>;
  };
};

const NO_LABEL_PREFIX = "NO-LABEL-PREFIX";

export const FieldSelectionInteractiveFormLiveStateContainer = (props: {
  fieldSelection: TaskConfigurationDataToType;
  labelPrefix?: string;
}) => {
  const { successToast, errorToast } = useElphiToast();
  const { partyState, selectedParty, setSelectedParty, updatePartiesHandler } =
    usePartyHooks();
  const { dealState, selectedDeal, updateDealsHandler } = useDealHooks();
  const {
    propertyState,
    selectedProperty,
    setSelectedProperty,
    updatePropertiesHandler
  } = usePropertyHooks();
  const { assetState, selectedAsset, setSelectedAsset, updateAssetsHandler } =
    useAssetHooks();
  const {
    statementState,
    updateStatementsHandler,
    selectedStatement,
    setSelectedStatement
  } = useStatementHooks();
  const { selectedTask } = useTaskHooks();
  const {
    creditReportState,
    selectedCreditScore,
    setSelectedCreditScore,
    updateCreditScoreHandler
  } = useCreditReportHooks();
  const {
    serviceProviderState,
    selectedServiceProvider,
    setSelectedServiceProvider,
    updateServiceProvidersHandler
  } = useServiceProviderHooks();
  const {
    insurancePolicyState,
    updateInsurancePolicyHandler,
    selectedInsurancePolicy,
    selectPolicy
  } = useInsurancePolicyHooks();

  const handleBatchUpdate = async (
    diff: Partial<FieldSelectionInteractiveFormState>
  ) => {
    return await Promise.all([
      updatePartiesHandler(diff.party).then((r) => {
        if (r?.status === 200) {
          successToast({
            title: "parties update success",
            description: `${r?.data?.batch?.length} parties updated`
          });
        }
        if (r?.status === 400) {
          errorToast({
            title: "parties update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      }),
      updateDealsHandler(diff.deal).then((r) => {
        if (r?.status === 200) {
          successToast({
            title: "deals update success",
            description: `${r?.data?.batch?.length} deals updated`
          });
        }
        if (r?.status === 400) {
          errorToast({
            title: "deals update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      }),
      updatePropertiesHandler(diff.property).then((r) => {
        if (r?.status === 200) {
          successToast({
            title: "properties update success",
            description: `${r?.data?.batch?.length} properties updated`
          });
        }
        if (r?.status === 400) {
          errorToast({
            title: "properties update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      }),
      updateAssetsHandler(diff.asset).then((r) => {
        if (r?.status === 200) {
          successToast({
            title: "assets update success",
            description: `${r?.data?.batch?.length} assets updated`
          });
        }
        if (r?.status === 400) {
          errorToast({
            title: "assets update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      }),
      updateStatementsHandler(diff.statement).then((r) => {
        if (r?.status === 200) {
          successToast({
            title: "statements update success",
            description: `${r?.data?.batch?.length} statements updated`
          });
        }
        if (r?.status === 400) {
          errorToast({
            title: "statements update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      }),
      updateCreditScoreHandler(diff["credit-score"]).then((r) => {
        if (r?.status === 200) {
          successToast({
            title: "credit scores update success",
            description: `${r?.data?.batch?.length} credit scores updated`
          });
        }
        if (r?.status === 400) {
          errorToast({
            title: "credit scores update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      }),
      updateServiceProvidersHandler(diff["service-provider"]),
      updateInsurancePolicyHandler(diff["insurance-policy"])
    ])
      .then((results): { status: 200 | 400 } => {
        if (results.find((v) => v?.status === 400)) {
          return { status: 400 };
        }
        return { status: 200 };
      })
      .catch((err): { status: 400 } => {
        console.log(err);
        return { status: 400 };
      });
  };
  return (
    <FieldSelectionInteractiveForm
      {...props}
      handleBatchUpdate={handleBatchUpdate}
      setSelectedParty={setSelectedParty}
      setSelectedProperty={setSelectedProperty}
      setSelectedAsset={setSelectedAsset}
      setSelectedStatement={setSelectedStatement}
      setSelectedCreditScore={setSelectedCreditScore}
      setSelectedServiceProvider={setSelectedServiceProvider}
      setSelectedInsurancePolicy={selectPolicy}
      selectedParty={selectedParty || undefined}
      selectedDeal={selectedDeal || undefined}
      selectedProperty={selectedProperty || undefined}
      selectedAsset={selectedAsset}
      selectedStatement={selectedStatement}
      selectedTask={selectedTask || undefined}
      selectedCreditScore={selectedCreditScore || undefined}
      selectedServiceProvider={
        selectedTask &&
        selectedTask.entityType === ElphiEntityType.serviceProvider
          ? serviceProviderState?.entities?.[selectedTask.entityId]
          : selectedServiceProvider || undefined
      }
      selectedInsurancePolicy={selectedInsurancePolicy || undefined}
      dealState={dealState}
      partyState={partyState}
      propertyState={propertyState}
      assetState={assetState}
      statementState={statementState}
      creditScoreState={creditReportState}
      serviceProviderState={serviceProviderState}
      insurancePolicyState={insurancePolicyState}
    />
  );
};
export const FieldSelectionInteractiveFormSnapshotContainer = (props: {
  fieldSelection: TaskConfigurationDataToType;
  labelPrefix?: string;
  snapshotId: string;
}) => {
  const { partyState, setSelectedParty } = usePartyHooks();
  const { dealState } = useDealHooks();
  const { propertyState, setSelectedProperty } = usePropertyHooks();
  const { assetState, setSelectedAsset } = useAssetHooks();
  const { statementState, setSelectedStatement } = useStatementHooks();
  const { taskState } = useTaskHooks();
  const { snapshotDataState } = useSnapshotHooks();
  const { creditReportState, setSelectedCreditScore } = useCreditReportHooks();
  const { serviceProviderState, setSelectedServiceProvider } =
    useServiceProviderHooks();

  const snapshot = snapshotDataState({
    snapshotId: props.snapshotId
  });
  if (
    !snapshot.dealState ||
    !snapshot.partyState ||
    !snapshot.propertyState ||
    !snapshot.assetState ||
    !snapshot.statementState ||
    !snapshot.creditReportState ||
    !snapshot.serviceProviderState ||
    !snapshot.insurancePolicyState
  ) {
    return <Progress size="xs" isIndeterminate />;
  }

  const { selectedTask } = useTaskHooks();

  const selectedServiceProvider =
    selectedTask && selectedTask.entityType === ElphiEntityType.serviceProvider
      ? snapshot?.serviceProviderState?.entities?.[selectedTask.entityId]
      : (serviceProviderState.selectedId &&
          snapshot.serviceProviderState?.entities[
            serviceProviderState.selectedId
          ]) ||
        undefined;

  return (
    <FieldSelectionInteractiveForm
      {...props}
      isReadOnly
      setSelectedParty={setSelectedParty}
      setSelectedProperty={setSelectedProperty}
      setSelectedAsset={setSelectedAsset}
      setSelectedStatement={setSelectedStatement}
      setSelectedCreditScore={setSelectedCreditScore}
      setSelectedServiceProvider={setSelectedServiceProvider}
      selectedParty={
        (partyState.selectedId &&
          snapshot.partyState?.entities[partyState.selectedId]) ||
        undefined
      }
      selectedDeal={
        (dealState.selectedId &&
          snapshot.dealState?.entities[dealState.selectedId]) ||
        undefined
      }
      selectedProperty={
        (propertyState.selectedId &&
          snapshot.propertyState?.entities[propertyState.selectedId]) ||
        undefined
      }
      selectedAsset={
        (assetState.selectedId &&
          snapshot.assetState?.entities[assetState.selectedId]) ||
        undefined
      }
      selectedStatement={
        (statementState.selectedId &&
          snapshot.statementState?.entities[statementState.selectedId]) ||
        undefined
      }
      selectedTask={
        (taskState.selectedId &&
          snapshot.taskState?.entities[taskState.selectedId]) ||
        undefined
      }
      selectedCreditScore={
        !!creditReportState.selectedId &&
        snapshot.creditReportState?.ids.includes(
          creditReportState.selectedId?.toString()
        )
          ? snapshot.creditReportState?.entities[creditReportState.selectedId]
          : undefined
      }
      selectedServiceProvider={selectedServiceProvider}
      dealState={snapshot.dealState}
      partyState={snapshot.partyState}
      propertyState={snapshot.propertyState}
      assetState={snapshot.assetState}
      statementState={snapshot.statementState}
      creditScoreState={snapshot.creditReportState}
      serviceProviderState={snapshot.serviceProviderState}
      insurancePolicyState={snapshot.insurancePolicyState}
    />
  );
};
export const FieldSelectionInteractiveFormContainer = (props: {
  fieldSelection: TaskConfigurationDataToType;
  labelPrefix?: string;
  snapshotId?: string;
}) => {
  return props.snapshotId ? (
    <FieldSelectionInteractiveFormSnapshotContainer
      {...props}
      snapshotId={props.snapshotId}
    />
  ) : (
    <FieldSelectionInteractiveFormLiveStateContainer {...props} />
  );
};

export const FieldSelectionInteractiveForm = (props: {
  isReadOnly?: boolean;
  fieldSelection: TaskConfigurationDataToType;
  labelPrefix?: string;
  setSelectedParty?: ReturnType<typeof usePartyHooks>["setSelectedParty"];
  setSelectedProperty?: ReturnType<
    typeof usePropertyHooks
  >["setSelectedProperty"];
  setSelectedAsset?: ReturnType<typeof useAssetHooks>["setSelectedAsset"];
  setSelectedStatement?: ReturnType<
    typeof useStatementHooks
  >["setSelectedStatement"];
  setSelectedCreditScore?: ReturnType<
    typeof useCreditReportHooks
  >["setSelectedCreditScore"];
  setSelectedServiceProvider?: ReturnType<
    typeof useServiceProviderHooks
  >["setSelectedServiceProvider"];
  setSelectedInsurancePolicy?: ReturnType<
    typeof useInsurancePolicyHooks
  >["selectPolicy"];
  selectedParty?: Party;
  selectedDeal?: Deal;
  selectedProperty?: Property;
  selectedAsset?: Asset;
  selectedStatement?: Statement;
  selectedCreditScore?: CreditScore;
  selectedTask?: Task;
  selectedServiceProvider?: RolodexServiceProvider;
  selectedInsurancePolicy?: BaseInsurancePolicy<InsurancePolicyEntityType>;
  dealState: EntityState<Deal>;
  partyState: PartySliceState | SnapshotDataStateFormat<Party>;
  propertyState: PropertySliceState;
  assetState: AssetSliceState;
  statementState: StatementSliceState;
  creditScoreState: CreditReportSliceState;
  serviceProviderState:
    | ServiceProviderSliceState
    | SnapshotDataStateFormat<RolodexServiceProvider>;
  insurancePolicyState:
    | SnapshotDataStateFormat<BaseInsurancePolicy<InsurancePolicyEntityType>>
    | InsurancePolicySliceState;
  handleBatchUpdate?: (
    diff: Partial<FieldSelectionInteractiveFormState>
  ) => Promise<{
    status: 200 | 400;
  }>;
}) => {
  const {
    handleBatchUpdate,
    setSelectedParty,
    setSelectedProperty,
    setSelectedAsset,
    setSelectedStatement,
    setSelectedCreditScore,
    setSelectedServiceProvider,
    setSelectedInsurancePolicy,
    selectedParty,
    selectedDeal,
    selectedProperty,
    selectedAsset,
    selectedStatement,
    selectedTask,
    dealState,
    partyState,
    propertyState,
    assetState,
    statementState,
    creditScoreState,
    selectedCreditScore,
    selectedServiceProvider,
    serviceProviderState,
    insurancePolicyState,
    selectedInsurancePolicy
  } = props;

  const { state, onChange, syncState } = useFormBuilderStateHandler({
    initialState: {} as FieldSelectionInteractiveFormState,
    callback: handleBatchUpdate,
    callbackOptions: {
      debounceRate: AppConfig.debounceRate,
      clearDiff: true
    }
  });
  const fieldsSpecs = getSpecs();
  useEffect(() => {
    selectedParty &&
      syncState({
        shouldSync: !!selectedParty,
        state: selectedParty,
        statePath: () => {
          if (selectedParty) {
            return ["party", selectedParty.id];
          }
        }
      });
  }, [selectedParty]);
  useEffect(() => {
    selectedDeal &&
      syncState({
        shouldSync: !!selectedDeal,
        state: selectedDeal,
        statePath: () => {
          if (selectedDeal) {
            return ["deal", selectedDeal.id];
          }
        }
      });
  }, [selectedDeal]);
  useEffect(() => {
    syncState({
      shouldSync: !!selectedProperty,
      state: selectedProperty,
      statePath: () => {
        if (selectedProperty) {
          return ["property", selectedProperty.id];
        }
      }
    });
  }, [selectedProperty]);
  useEffect(() => {
    syncState({
      shouldSync: !!selectedAsset,
      state: selectedAsset,
      statePath: () => {
        if (selectedAsset) {
          return ["asset", selectedAsset.id];
        }
      }
    });
  }, [selectedAsset]);
  useEffect(() => {
    syncState({
      shouldSync: !!selectedStatement,
      state: selectedStatement,
      statePath: () => {
        if (selectedStatement) {
          return ["statement", selectedStatement.id];
        }
      }
    });
  }, [selectedStatement]);

  useEffect(() => {
    syncState({
      shouldSync: !!selectedCreditScore,
      state: selectedCreditScore,
      statePath: () => {
        if (selectedCreditScore) {
          return ["creditScore", selectedCreditScore.id];
        }
      }
    });
  }, [selectedCreditScore]);

  useEffect(() => {
    syncState({
      shouldSync: !!selectedServiceProvider,
      state: selectedServiceProvider,
      statePath: () => {
        if (selectedServiceProvider) {
          return ["serviceProvider", selectedServiceProvider.id];
        }
      }
    });
  }, [selectedServiceProvider]);

  useEffect(() => {
    syncState({
      shouldSync: !!selectedInsurancePolicy,
      state: selectedInsurancePolicy,
      statePath: () => {
        if (selectedInsurancePolicy) {
          return ["insurancePolicy", selectedInsurancePolicy.id];
        }
      }
    });
  }, [selectedInsurancePolicy]);

  const getEntityIdFromTask = (
    entityType: ElphiEntityType,
    selectedEntity?: 0 | "" | { id: string }
  ) => {
    return selectedTask && selectedTask.entityType === entityType
      ? selectedTask.entityId
      : selectedEntity
      ? selectedEntity.id
      : "";
  };

  const specs: {
    prefix: ElphiEntityType;
    id: string;
    storeState: EntityState<unknown>;
    fieldSpecs: EntityFormFieldSpecs<any>;
  }[] = [
    {
      prefix: ElphiEntityType.deal,
      id: getEntityIdFromTask(ElphiEntityType.deal, selectedDeal),
      storeState: dealState,
      fieldSpecs: fieldsSpecs.deal?.entitySpecs || {}
    },
    {
      prefix: ElphiEntityType.property,
      id: getEntityIdFromTask(ElphiEntityType.property, selectedProperty),
      storeState: propertyState,
      fieldSpecs: fieldsSpecs.property?.entitySpecs || {}
    },
    {
      prefix: ElphiEntityType.party,
      id: getEntityIdFromTask(ElphiEntityType.party, selectedParty),
      storeState: partyState,
      fieldSpecs: fieldsSpecs.party?.entitySpecs || {}
    },
    {
      prefix: ElphiEntityType.asset,
      id: getEntityIdFromTask(ElphiEntityType.asset, selectedAsset),
      storeState: assetState,
      fieldSpecs: fieldsSpecs.asset?.entitySpecs || {}
    },
    {
      prefix: ElphiEntityType.statement,
      id: getEntityIdFromTask(ElphiEntityType.statement, selectedStatement),
      storeState: statementState,
      fieldSpecs: fieldsSpecs.statement?.entitySpecs || {}
    },
    {
      prefix: ElphiEntityType.creditScore,
      id: getEntityIdFromTask(ElphiEntityType.creditScore, selectedCreditScore),
      storeState: creditScoreState,
      fieldSpecs: fieldsSpecs[ElphiEntityType.creditScore]?.entitySpecs || {}
    },
    {
      prefix: ElphiEntityType.serviceProvider,
      id: getEntityIdFromTask(
        ElphiEntityType.serviceProvider,
        selectedServiceProvider
      ),
      storeState: serviceProviderState,
      fieldSpecs:
        selectedServiceProvider?.type === ServiceProviderType.Company
          ? fieldsSpecs?.[ElphiEntityType.serviceProvider]?.specsParts
              ?.fullCompanyServiceProviderFieldsSpecs || {}
          : selectedServiceProvider?.type === ServiceProviderType.Branch
          ? fieldsSpecs?.[ElphiEntityType.serviceProvider]?.specsParts
              ?.fullBranchServiceProviderFieldsSpecs || {}
          : selectedServiceProvider?.type === ServiceProviderType.Representative
          ? fieldsSpecs?.[ElphiEntityType.serviceProvider]?.specsParts
              ?.fullRepServiceProviderFieldsSpecs || {}
          : {}
    },
    {
      prefix: ElphiEntityType.insurancePolicy,
      id: getEntityIdFromTask(
        ElphiEntityType.insurancePolicy,
        selectedInsurancePolicy
      ),
      storeState: insurancePolicyState,
      fieldSpecs:
        fieldsSpecs[ElphiEntityType.insurancePolicy]?.specsParts
          ?.fullBaseInsurancePolicyFieldFieldSpecs || {}
    }
  ];

  useEffect(() => {
    specs.forEach((spec) => {
      syncState({
        state: spec.storeState.entities[spec.id] as EntityState<unknown>,
        shouldSync: !!spec.storeState.entities[spec.id],
        statePath: () => {
          return [spec.prefix, spec.id];
        }
      });
    });
  }, [
    dealState.entities,
    partyState.entities,
    propertyState.entities,
    assetState.entities,
    statementState.entities,
    creditScoreState.entities,
    serviceProviderState.entities,
    insurancePolicyState.entities
  ]);

  const hasPropertyFieldsToValidate =
    selectedTask &&
    selectedTask?.[props.fieldSelection]?.property &&
    Object.keys(selectedTask?.[props.fieldSelection]!.property).length > 0;
  const hasPartyFieldsToValidate =
    selectedTask &&
    selectedTask?.[props.fieldSelection]?.party &&
    Object.keys(selectedTask?.[props.fieldSelection]!.party).length > 0;
  const hasAssetFieldsToValidate =
    selectedTask &&
    selectedTask?.[props.fieldSelection]?.asset &&
    Object.keys(selectedTask?.[props.fieldSelection]!.asset).length > 0;
  const hasStatementFieldsToValidate =
    selectedTask &&
    selectedTask?.[props.fieldSelection]?.statement &&
    Object.keys(selectedTask?.[props.fieldSelection]!.statement).length > 0;
  const hasCreditScoreFieldsToValidate =
    selectedTask &&
    selectedTask?.[props.fieldSelection]?.["credit-score"] &&
    Object.keys(selectedTask?.[props.fieldSelection]?.["credit-score"] || {})
      .length > 0;
  const hasServiceProviderFieldsToValidate =
    selectedTask &&
    selectedTask?.[props.fieldSelection]?.["service-provider"] &&
    Object.keys(
      selectedTask?.[props.fieldSelection]?.["service-provider"] || {}
    ).length > 0;
  const hasInsurancePolicyFieldsToValidate =
    selectedTask &&
    selectedTask?.[props.fieldSelection]?.["insurance-policy"] &&
    Object.keys(
      selectedTask?.[props.fieldSelection]?.["insurance-policy"] || {}
    ).length > 0;

  const entitySelects = useMemo(() => {
    const specPrefixes = specs.map((s) => s.prefix);

    const currentTask = selectedTask!;
    const currentParty =
      selectedTask && selectedTask?.entityType === ElphiEntityType.party
        ? partyState?.entities[selectedTask?.entityId]
        : selectedParty;
    const currentAsset =
      selectedTask && selectedTask?.entityType === ElphiEntityType.asset
        ? assetState?.entities[selectedTask?.entityId]
        : selectedAsset;

    const selectPropertyLogic =
      hasPropertyFieldsToValidate &&
      currentTask &&
      currentTask?.entityType !== "property";

    const selectPartyLogic =
      (hasPartyFieldsToValidate ||
        hasAssetFieldsToValidate ||
        hasStatementFieldsToValidate) &&
      currentTask &&
      !(
        currentTask?.entityType === "asset" &&
        specPrefixes.includes(ElphiEntityType.statement)
      ) &&
      currentTask?.entityType !== "party";

    const selectAssetLogic =
      (hasAssetFieldsToValidate || hasStatementFieldsToValidate) &&
      currentTask &&
      (selectedParty || currentTask?.entityType === "party") &&
      currentTask?.entityType !== "asset";

    const selectCreditScoreLogic =
      hasCreditScoreFieldsToValidate &&
      currentTask &&
      (selectedParty || currentTask?.entityType === "party") &&
      currentTask?.entityType !== "credit-score";

    const selectStatementLogic =
      hasStatementFieldsToValidate &&
      currentTask &&
      (selectedAsset || currentTask?.entityType === "asset") &&
      currentTask?.entityType !== "statement";

    const selectServiceProviderLogic =
      hasServiceProviderFieldsToValidate &&
      currentTask &&
      currentTask.entityType !== ElphiEntityType.serviceProvider;

    const selectInsurancePolicyLogic =
      hasInsurancePolicyFieldsToValidate &&
      currentTask &&
      currentTask.entityType !== ElphiEntityType.insurancePolicy;

    const hasFieldsToValidate =
      flatten(
        specs.map((spec) =>
          Object.keys(selectedTask?.[props.fieldSelection]?.[spec.prefix] || {})
        )
      ).length > 0;

    const onSelectParty = (v: string) => {
      setSelectedParty?.(v);
      if (v === "") {
        setSelectedAsset?.(v);
        setSelectedStatement?.(v);
      }
    };
    const onSelectAsset = (v: string) => {
      setSelectedAsset?.(v);
      if (v === "") setSelectedStatement?.(v);
    };

    const onSelectCreditScore = (v: string) => {
      setSelectedCreditScore?.(v);
    };

    const onSelectServiceProvider = (v: string) => {
      setSelectedServiceProvider?.(v);
    };

    const onSelectInsurancePolicy = (v: string) => {
      setSelectedInsurancePolicy?.({ id: v });
    };

    return (
      <Box>
        {hasFieldsToValidate && (
          <Box bgColor="white">
            {(selectPropertyLogic ||
              selectPartyLogic ||
              selectAssetLogic ||
              selectCreditScoreLogic ||
              selectStatementLogic ||
              selectServiceProviderLogic ||
              selectInsurancePolicyLogic) && (
              <Box borderTopColor={"grey.100"} borderTopWidth={"5px"}>
                {props.labelPrefix && (
                  <SectionHeader
                    header={`Choose ${props.labelPrefix} Entities`}
                  />
                )}
                <Flex flexDirection={"row"} flexWrap={"wrap"}>
                  {selectPropertyLogic && (
                    <Box w="50%">
                      <DealPropertySearchContainer
                        fieldType={FieldType.SingleSelect}
                        currentValue={
                          selectedProperty ? selectedProperty.id : ""
                        }
                        onSelect={(v: string) => setSelectedProperty?.(v)}
                        dealId={selectedDeal ? selectedDeal.id : ""}
                        label={"select property"}
                      />
                    </Box>
                  )}

                  {selectPartyLogic && (
                    <Box w="50%">
                      <DealPartySearch
                        fieldType={FieldType.SingleSelect}
                        currentValue={selectedParty ? selectedParty.id : ""}
                        onSelect={onSelectParty}
                        dealId={selectedDeal ? selectedDeal.id : ""}
                        label={"select party"}
                      />
                    </Box>
                  )}

                  {selectAssetLogic && (
                    <Box w="50%">
                      <AssetQuerySearch
                        fieldType={FieldType.SingleSelect}
                        currentValue={selectedAsset ? selectedAsset.id : ""}
                        onSelect={onSelectAsset}
                        selectedParty={currentParty ? currentParty : {}}
                        label={"select asset"}
                        query={assetQueryType.onlyPartyAssets}
                      />
                    </Box>
                  )}

                  {selectStatementLogic && (
                    <Box w="50%">
                      <StatementQuerySearch
                        fieldType={FieldType.SingleSelect}
                        currentValue={
                          selectedStatement ? selectedStatement.id : ""
                        }
                        onSelect={(v: string) => setSelectedStatement?.(v)}
                        selectedAsset={currentAsset ? currentAsset : {}}
                        label={"select statement"}
                        query={statementQueryType.onlyAssetStatements}
                      />
                    </Box>
                  )}

                  {selectCreditScoreLogic && (
                    <Box w="50%">
                      <CreditScoreSearch
                        fieldType={FieldType.SingleSelect}
                        currentValue={
                          selectedCreditScore ? selectedCreditScore.id : ""
                        }
                        onSelect={onSelectCreditScore}
                        selectedParty={currentParty ? currentParty : {}}
                        label={"select credit score"}
                        query={creditScoreQueryType.onlyPartyCreditScore}
                      />
                    </Box>
                  )}

                  {selectServiceProviderLogic && (
                    <Box w="50%">
                      <ServiceProviderSelect
                        currentValue={selectedServiceProvider?.id || EMPTY}
                        onSelect={onSelectServiceProvider}
                        dealId={selectedDeal?.id || EMPTY}
                      />
                    </Box>
                  )}

                  {selectInsurancePolicyLogic && (
                    <Box w="50%">
                      <InsurancePolicySearch
                        currentValue={selectedInsurancePolicy?.id || EMPTY}
                        onSelect={onSelectInsurancePolicy}
                      />
                    </Box>
                  )}
                </Flex>
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }, [
    selectedTask,
    specs,
    selectedStatement,
    selectedAsset,
    selectedDeal,
    selectedProperty,
    selectedParty,
    selectedCreditScore,
    selectedServiceProvider,
    selectedInsurancePolicy,
    props.fieldSelection,
    props.labelPrefix
  ]);

  return (
    <Box w="100%" h="100%">
      {entitySelects}
      {selectedTask &&
        specs.map((spec, i) => {
          return (
            <Box key={i}>
              {spec.id &&
                Object.keys(
                  selectedTask?.[props.fieldSelection]?.[spec.prefix] || {}
                ).length > 0 && (
                  <FormBuilder
                    customKey={`fieldSelectionInteractiveForm-${
                      props.labelPrefix || NO_LABEL_PREFIX
                    }-${spec.prefix}`}
                    isDisabled={props.isReadOnly}
                    key={i}
                    size={{
                      minW: "300px"
                    }}
                    onChange={(e) =>
                      !props.isReadOnly &&
                      onChange({
                        ...e,
                        fieldKey: [spec.prefix, spec.id, ...e.fieldKey]
                      })
                    }
                    sections={[
                      buildSectionFromEntity({
                        labelPrefix: props.labelPrefix,
                        fieldSelection: props.fieldSelection,
                        attachedComponet: (t) => {
                          return (
                            <AttachedFieldSelectionStatusComponet
                              state={state}
                              spec={spec}
                              onChange={onChange}
                              isDisabled={props?.isReadOnly}
                              fieldId={t.fieldId}
                              fieldPath={t.fieldPath}
                              prefix={[]}
                            />
                          );
                        },
                        currentTask: selectedTask,
                        state, // shady
                        storeState: spec.storeState,
                        entity: spec.prefix,
                        selectedId: spec.id,
                        fieldSpecs: spec.fieldSpecs
                      })
                    ]}
                  />
                )}
            </Box>
          );
        })}
    </Box>
  );
};
export const buildSectionFromEntity = <T extends object>(props: {
  fieldSelection: TaskConfigurationDataToType;
  labelPrefix?: string;
  attachedComponet?: (t: {
    fieldId: string;
    fieldPath: string[];
  }) => JSX.Element;
  selectedId: string;
  storeState: EntityState<T>;
  entity: ElphiEntityType;
  state: FieldSelectionInteractiveFormState;
  fieldSpecs: EntityFormFieldSpecs<T>;
  currentTask?: Task;
}): Section => {
  if (
    props.currentTask?.[props.fieldSelection] &&
    props.currentTask?.[props.fieldSelection]?.[props.entity]
  ) {
    const fieldSelection =
      props.currentTask?.[props.fieldSelection]?.[props.entity];
    const sortedFieldSelection =
      (fieldSelection && getSortedData(fieldSelection)) || {};

    const sectionInputs = Object.keys(sortedFieldSelection).map((k) => {
      if (!k) return null;
      const fieldPath =
        props.currentTask?.[props.fieldSelection]?.[props.entity][
          k
        ]?.fieldPath?.split(".");
      if (!fieldPath) return null;
      const selectedEntity = props.state?.[props.entity]?.[props.selectedId];
      if (!selectedEntity) return null;
      const isAggregation = isAggregationPath(fieldPath);
      const focused = isAggregation
        ? get(selectedEntity, [...fieldPath, "focused"])
        : undefined;
      const pathWithFocus: string[] = [...fieldPath, focused].filter(
        removeEmpty
      );
      const fieldValue = get(selectedEntity, pathWithFocus);
      const fieldSpecs = get(props.fieldSpecs, pathWithFocus);

      if (
        !fieldSpecs?.fieldType &&
        !fieldSpecs?.label &&
        !fieldSpecs?.fieldKey
      ) {
        //do not remove this log or the comment
        console.log("fieldSpecs is empty: ", fieldSpecs, fieldPath);
        return null;
      }
      const fieldStatus =
        get(selectedEntity, ["fieldMeta", ...fieldPath, "status"]) ||
        get(props.storeState.entities[props.selectedId], [
          "fieldMeta",
          ...fieldPath,
          "status"
        ]);

      const isReadOnly =
        fieldStatus === FieldStatus.Approved ||
        [
          AggregationFocusType.ThirdParty,
          AggregationFocusType.Calculated
        ].includes(focused);

      return {
        currentValue: fieldValue,
        fieldKey: pathWithFocus,
        isReadOnly: fieldStatus === FieldStatus.Approved || isReadOnly,
        customColor: fieldStatus
          ? fieldStatusToFieldColor[fieldStatus]
          : undefined,
        formatter: fieldSpecs?.formatter,
        label: `${fieldSpecs?.label || "no label"}`,
        fieldType: fieldSpecs.fieldType,
        options: fieldSpecs.options,
        componentOverride: fieldSpecs.componentOverride,
        isValid:
          fieldValue &&
          fieldSpecs?.fieldType &&
          fieldTypeValidation?.[fieldSpecs?.fieldType]?.(fieldValue) &&
          additionalValidations(fieldSpecs, fieldValue),
        labelPosition: "up",
        fieldStatus,
        attachedComponent:
          props.attachedComponet &&
          props.attachedComponet({
            fieldPath: pathWithFocus,
            fieldId: k
          })
      };
    });

    return {
      header: `${props.labelPrefix ? props.labelPrefix + " - " : ""}${
        entityToHeader[props.entity]
      }`,
      inputs: sectionInputs.filter((v) => !!v) as InputBuilderSpecs[]
    };
  }
  return {
    isHidden: true,
    inputs: []
  };
};
