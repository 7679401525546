import { ElphiEntityType } from "@elphi/types";
import { SpecsEntry } from "../../types/dynamicSchema.types";
import {
  DealIntegrationsFieldSpecs,
  dealIntegrationsFieldSpecs
} from "./deal/deal.fields";
import {
  PropertyIntegrationsFieldSpecs,
  propertyIntegrationsFieldSpecs
} from "./property/property.fields";

type DealIntegrations = {
  [ElphiEntityType.deal]: SpecsEntry<DealIntegrationsFieldSpecs>;
};

type PropertyIntegrations = {
  [ElphiEntityType.property]: SpecsEntry<PropertyIntegrationsFieldSpecs>;
};

export type IntegrationsSpecs = DealIntegrations & PropertyIntegrations;

export const integrationSpecs: IntegrationsSpecs = {
  [ElphiEntityType.deal]: {
    entitySpecs: dealIntegrationsFieldSpecs,
    specsParts: {}
  },
  [ElphiEntityType.property]: {
    entitySpecs: propertyIntegrationsFieldSpecs,
    specsParts: {}
  }
};
