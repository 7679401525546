import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  IconButton,
  Link,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { removeNulls } from "@elphi/aggregations/src/utils/filter.utils";
import { AggregationType, Deal, ElphiEntityType, Party } from "@elphi/types";
import { keyBy } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import useDealHooks from "../../../../hooks/deal.hooks";
import { useDealPartyRelationHooks } from "../../../../hooks/dealpartyrelation.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import { usePartyHooks } from "../../../../hooks/party.hooks";
import { usePartyUserRelationHooks } from "../../../../hooks/partyuser.hooks";
import PathVariables from "../../../../routes/pathVariables";
import { actionStyles, milestoneStyles } from "../../../deal/styles/table";
import { DealMilestoneCell } from "../../../deal/table/v2/DealMilestoneCell";
import {
  BorrowingEntityV2 as BorrowingEntity,
  Sponsor
} from "../../../deal/utils/table.utils";
import { ElphiListRow } from "../../../elphi-list/ElphiListRow";
import { ValueContainer } from "../../../form-builder/InputBuilder";
import { getFocusedAggregationSpecs } from "../../../form-builder/field-specs/utils/aggregation.utils";
import PartyUserAssignmentContainer from "../../../los-user/PartyUserAssignmentContainer";
import { ElphiCellType, OrderableTableProps } from "../../../table/table.types";
import { getPartyName } from "../../../task/taskPrint.utils";
import { TableInput } from "../../../utils/tableUtils";
import { closingDateStyles } from "../../styles/table";
import { ItemsMenuCopyWidget } from "./ItemsMenuCopyWidget";
import { usePartyTableState } from "./hooks";
export const PartyRow = (
  props: {
    partyId: string;
    dealPartyId?: string;
    index?: string;
    partyTableState: ReturnType<typeof usePartyTableState>;
  } & OrderableTableProps
) => {
  const { tableName, isOrderable } = props;
  const { dealState } = useDealHooks();
  const { partyState, setSelectedParty } = usePartyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { partyUserRelationState } = usePartyUserRelationHooks();
  const { selectedOrgId } = useOrgHooks();
  const {
    syncDealPartyRelationState,
    syncPartyState,
    syncDealState,
    localPartyState,
    localDealPartyRelationState,
    localDealState,
    partyOnChange,
    dealOnChange,
    dealPartyRelationOnChange
  } = props.partyTableState;
  const fieldsSpecs = getSpecs();

  useEffect(() => {
    const { dealPartyId } = props;
    const dealId =
      dealPartyId && dealPartyRelationState.entities?.[dealPartyId]?.dealId;

    if (dealId) {
      const dealPartyIds = Object.values(dealPartyRelationState.entities)
        .filter(removeNulls)
        ?.filter((dpr) => dpr.dealId === dealId)
        .map((dpr) => dpr.id);

      dealPartyIds.forEach((dpid) =>
        syncDealPartyRelationState({
          shouldSync: !!dealPartyRelationState.entities?.[dpid],
          state: dealPartyRelationState.entities?.[dpid],
          statePath: () => ["dealPartyRelations", dpid]
        })
      );

      const dealPartyRelationsToSyncRemoved = Object.values(
        localDealPartyRelationState.dealPartyRelations
      )
        .filter(
          (localDpr) =>
            localDpr.dealId === dealId && !dealPartyIds.includes(localDpr.id)
        )
        .map((localDpr) => localDpr.id);

      dealPartyRelationsToSyncRemoved?.forEach((dpid) =>
        syncDealPartyRelationState({
          shouldSync: true,
          state: {},
          statePath: () => ["dealPartyRelations", dpid]
        })
      );
    }
  }, [dealPartyRelationState.entities]);

  useEffect(
    () =>
      syncPartyState({
        shouldSync: !!partyState.entities?.[props.partyId],
        state: partyState.entities?.[props.partyId],
        statePath: () => {
          return ["parties", props.partyId];
        }
      }),
    [partyState.entities?.[props.partyId]]
  );
  const dealId =
    props.dealPartyId &&
    dealPartyRelationState.entities?.[props.dealPartyId]?.dealId;

  useEffect(
    () =>
      syncDealState({
        shouldSync: !!dealId && !!dealState.entities?.[dealId],
        state: dealId ? dealState.entities?.[dealId] : {},
        statePath: () => {
          if (dealId) {
            return ["deals", dealId];
          }
        }
      }),
    [dealId && dealState.entities?.[dealId]]
  );

  const rowCells = useCallback(
    (rowData: {
      relatedParty?: Party[];
      party: Party;
      deal?: Deal;
      index: string | number;
    }) => {
      const { deal } = rowData;
      const dealPartyRelation =
        deal && rowData.party
          ? localDealPartyRelationState.dealPartyRelations[
              `${deal.id}_${rowData.party.id}`
            ]
          : undefined;

      const dealPartyRelations =
        deal && rowData.party && localDealPartyRelationState?.dealPartyRelations
          ? Object.values(localDealPartyRelationState.dealPartyRelations)
              .filter((dpr) => !!dpr && dpr.dealId === deal.id)
              .filter(removeNulls)
          : [];

      return {
        cells: [
          {
            index: 0,
            data: (
              <Text fontSize="16px" fontStyle={"bold"} align={"center"}>
                {rowData.index.toString()}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "80px",
            minWidth: "80px"
          },
          {
            index: 1,
            data: (
              <Flex pl="20px" justifyContent={"space-between"}>
                <Tooltip label="edit party">
                  <Box>
                    <IconButton
                      size="xs"
                      aria-label="edit party"
                      icon={<EditIcon />}
                      isDisabled={!rowData.party?.id}
                      onClick={() =>
                        rowData.party?.id && setSelectedParty(rowData.party?.id)
                      }
                    />
                  </Box>
                </Tooltip>

                <Tooltip
                  label={
                    rowData.party?.id
                      ? `new tab party: ${getPartyName(rowData.party)}`
                      : ""
                  }
                >
                  <Box>
                    <Link
                      href={
                        rowData.party?.id &&
                        `../../${selectedOrgId?.toString()}${
                          PathVariables.PartiesPipeline
                        }?party=${rowData.party?.id}`
                      }
                      isExternal
                    >
                      <IconButton
                        bgColor={
                          elphiTheme.components.light.button.navigation
                            .goToParty
                        }
                        size="xs"
                        aria-label="edit party"
                        icon={<ExternalLinkIcon />}
                      />
                    </Link>
                  </Box>
                </Tooltip>
                <Tooltip
                  label={
                    !deal?.id
                      ? "party is not assigned to a deal"
                      : `new tab deal: ${deal.LoanIdentifier}`
                  }
                >
                  <Box>
                    <Link
                      href={
                        deal?.id &&
                        `../../${selectedOrgId?.toString()}${
                          PathVariables.WorksheetPath
                        }/${dealId}`
                      }
                      isExternal
                    >
                      <IconButton
                        bgColor={
                          elphiTheme.components.light.button.navigation.goToDeal
                        }
                        size="xs"
                        aria-label="edit worksheet"
                        icon={<ExternalLinkIcon />}
                        isDisabled={!deal?.id}
                      />
                    </Link>
                  </Box>
                </Tooltip>
                <Tooltip label={"system identifiers"} placement="top">
                  <Box pr="2px">
                    <ItemsMenuCopyWidget
                      items={[
                        { label: "elphi-party-id", value: rowData?.party?.id },
                        { label: "crm-party-id", value: rowData?.party?.CRMId },
                        {
                          label: "elphi-deal-id",
                          value: rowData?.deal?.id
                        },
                        {
                          label: "crm-deal-id",
                          value: rowData?.deal?.CRMId
                        },
                        {
                          label: "loan number",
                          value: rowData?.deal?.LoanIdentifier
                        }
                      ]}
                    />
                  </Box>
                </Tooltip>
              </Flex>
            ),
            type: ElphiCellType.Element,
            maxWidth: actionStyles.maxWidth,
            minWidth: actionStyles.minWidth
          },
          {
            index: 2,
            data: (
              <Text fontSize="16px" fontStyle={"bold"} align={"center"}>
                {rowData.party && getPartyName(rowData.party)}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "150px",
            minWidth: "150px"
          },
          {
            index: 3,
            data: (
              <Box>
                {rowData.party && (
                  <TableInput
                    specFields={
                      rowData.party.PartyType === "Individual"
                        ? fieldsSpecs.party?.specsParts
                            ?.individualPartyFieldSpecs?.LOCStatusType
                        : fieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
                            ?.PartyMilestone
                    }
                    prefix={["parties", rowData.party.id]}
                    state={localPartyState}
                    currentEntity={rowData.party}
                    onChange={partyOnChange}
                  />
                )}
              </Box>
            ),
            type: ElphiCellType.Element,
            maxWidth: "300px",
            minWidth: "300px"
          },
          {
            index: 4,
            data: rowData.party?.id ? (
              <Box>
                <PartyUserAssignmentContainer partyId={rowData.party?.id} />
              </Box>
            ) : (
              <Box>N/A</Box>
            ),
            type: ElphiCellType.Element,
            maxWidth: "250px",
            minWidth: "250px"
          },
          {
            index: 5,
            data: (
              <Text align={"center"} fontStyle="bold" fontSize="16px">
                {deal?.LoanIdentifier}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "150px",
            minWidth: "150px"
          },
          {
            index: 6,
            data: (
              <Center>
                <BorrowingEntity
                  currentParties={Object.values(partyState?.entities)}
                  dealPartyRelations={dealPartyRelations}
                />
              </Center>
            ),
            type: ElphiCellType.Element,
            maxWidth: "150px",
            minWidth: "150px"
          },
          {
            index: 7,
            data: (
              <Center>
                <Sponsor
                  currentParties={Object.values(partyState?.entities)}
                  dealPartyRelations={{
                    ids: dealPartyRelations.map((dpr) => dpr.id),
                    entities: keyBy(dealPartyRelations, "id")
                  }}
                />
              </Center>
            ),
            type: ElphiCellType.Element,
            maxWidth: "150px",
            minWidth: "150px"
          },
          {
            index: 8,
            data: (
              <Box>
                {deal?.id && (
                  <TableInput
                    isAggregation={AggregationType.ThirdParty}
                    specFields={getFocusedAggregationSpecs({
                      spec: fieldsSpecs?.deal?.entitySpecs?.aggregations
                        ?.LoanProgramType,
                      focused: deal?.id
                        ? localDealState.deals[deal.id]?.aggregations
                            ?.LoanProgramType?.focused
                        : undefined
                    })}
                    prefix={deal?.id ? ["deals", deal.id] : []}
                    state={localDealState}
                    currentEntity={
                      deal?.id ? localDealState.deals[deal.id] : { id: "" }
                    }
                    onChange={dealOnChange}
                  />
                )}
              </Box>
            ),
            type: ElphiCellType.Element,
            maxWidth: "350px",
            minWidth: "350px"
          },
          {
            index: 9,
            data: (
              <Box w="100%">
                {deal?.id && rowData.party && (
                  <TableInput
                    isAggregation={AggregationType.Aggregation}
                    specFields={getFocusedAggregationSpecs({
                      focused:
                        localDealState.deals[deal.id]?.aggregations
                          ?.RequestedLoanAmount?.focused,
                      spec: fieldsSpecs?.deal?.entitySpecs?.aggregations
                        ?.RequestedLoanAmount
                    })}
                    prefix={["deals", deal.id]}
                    state={localDealState}
                    currentEntity={localDealState.deals[deal.id]}
                    onChange={dealOnChange}
                  />
                )}
              </Box>
            ),
            type: ElphiCellType.Element,
            maxWidth: "400px",
            minWidth: "400px"
          },
          {
            index: 10,
            type: ElphiCellType.Element,
            maxWidth: "350px",
            minWidth: "350px",
            data: (
              <Box>
                {deal?.id && rowData.party && dealPartyRelation && (
                  <TableInput
                    specFields={
                      rowData.party.PartyType === "Individual"
                        ? fieldsSpecs[ElphiEntityType.dealParty]?.specsParts
                            ?.dealToIndividualFieldSpecs?.relationRoleType
                        : fieldsSpecs[ElphiEntityType.dealParty]?.specsParts
                            ?.dealToEntityFieldSpecs?.relationRoleType
                    }
                    prefix={[
                      "dealPartyRelations",
                      `${deal.id}_${rowData.party.id}`
                    ]}
                    state={localDealPartyRelationState}
                    currentEntity={dealPartyRelation}
                    onChange={dealPartyRelationOnChange}
                    customComponent={{
                      ValueContainer: ValueContainer
                    }}
                    hideSelectedOptions={false}
                  />
                )}
              </Box>
            )
          },
          {
            index: 11,
            data: (
              <Text align={"center"}>
                {deal?.aggregations?.EstimatedClosingDate &&
                  printDateTime(
                    String(
                      getFocusedData(deal?.aggregations?.EstimatedClosingDate)
                    )
                  )}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: closingDateStyles.maxWidth,
            minWidth: closingDateStyles.minWidth
          },
          {
            index: 12,
            data: <DealMilestoneCell deal={deal} />,
            type: ElphiCellType.Element,
            maxWidth: milestoneStyles.maxWidth,
            minWidth: milestoneStyles.minWidth
          }
        ],
        index: props.index,
        minHeight: "53px",
        maxHeight: "53px"
      };
    },
    [
      localDealState,
      localPartyState,
      localDealPartyRelationState,
      dealState,
      partyState,
      dealPartyRelationState,
      partyUserRelationState,
      props.partyId,
      props.dealPartyId,
      selectedOrgId
    ]
  );
  const cellsMemo = useMemo(() => {
    const rowData =
      rowCells({
        deal: dealId ? localDealState?.deals?.[dealId]! : undefined,
        party: localPartyState?.parties?.[props.partyId]!,
        relatedParty: [],
        index: props.index!
      }) || [];

    return rowData;
  }, [
    localDealState,
    localPartyState,
    localDealPartyRelationState,
    dealState,
    partyState,
    dealPartyRelationState,
    props.partyId,
    props.dealPartyId
  ]);
  return (
    <Box w="100%">
      <ElphiListRow
        tableName={tableName}
        isOrderable={isOrderable}
        cells={cellsMemo.cells}
      />
    </Box>
  );
};
