import { PartyRelation, PartyRelationType } from "@elphi/types";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";

import {
  EntityToEntityRelationFieldSpecs,
  EntityToIndividualRelationFieldSpecs
} from "../../../../components/form-builder/field-specs/party-relation/party-relation.fields";
import { addRulesToInputs, buildSection } from "../../utils/formBuilder.utils";
import {
  ownershipPercentage,
  partyRelationRoleType
} from "../inputs/partyRelation.inputs";
import {
  BaseSchemaPartyRelationSections,
  BaseSchemaSectionsBuilders
} from "../types";

const partyRelationSection =
  (specs: {
    entityToEntityRelationFieldSpecs: EntityToEntityRelationFieldSpecs;
    entityToIndividualRelationFieldSpecs: EntityToIndividualRelationFieldSpecs;
  }) =>
  (r: {
    state: PartyRelation;
    onChange: (v: OnChangeInput) => void;
    options?: {
      header?: string;
      hideAttachedComponent?: boolean;
      isReadOnly?: boolean;
    };
  }) => {
    const { state, onChange, options } = r;

    const type = state?.type;
    const fieldSpecs =
      type === PartyRelationType.EntityToEntity
        ? specs.entityToEntityRelationFieldSpecs
        : specs.entityToIndividualRelationFieldSpecs;

    return buildSection({
      state: r.state,
      onChange,
      header: options?.header || "Relation",
      inputs: addRulesToInputs({
        inputs: [ownershipPercentage, partyRelationRoleType],
        rules: [
          {
            field: "isReadOnly",
            predicate: () => !!options?.isReadOnly
          }
        ],
        state: r.state
      }),
      fieldSpecs
    });
  };

export const partyRelationSectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaPartyRelationSections> =
  { partyRelationSection };
