import { Box, Heading } from "@chakra-ui/react";
import { usePropertyHooks } from "../../hooks/property.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
import ScrollableSections from "../ScrollableSections";
import PropertyAssessmentsTable from "../application/PropertyAssessmentsTable";
import TaxAssessmentsTable from "../application/TaxAssessmentsTable";
import ConstructionManagementSections from "./ConstructionManagementSections";
import LeasingTable from "./LeasingTable";
import SectionsAfterTables from "./SectionsAfterTables";
import SectionsBeforeTables from "./SectionsBeforeTables";

const PropertyPageForm = (props: { navigationPath: NavigationPath }) => {
  const { selectedProperty } = usePropertyHooks();
  const { navigationPath } = props;
  return (
    <Box h={`calc(100%)`} overflow="scroll">
      {selectedProperty && (
        <Box h="100%">
          <Box bgColor="white">
            <SectionsBeforeTables
              selectedProperty={selectedProperty}
              navigationPath={navigationPath}
            />
          </Box>
          <Box bgColor="white">
            <ScrollableSections
              customKey="leasingTable"
              sections={[
                {
                  index: 0,
                  header: <Heading>Leasing Table</Heading>,
                  body: <LeasingTable selectedProperty={selectedProperty} />
                }
              ]}
              navigationPath={navigationPath}
            />
          </Box>
          <Box bgColor="white">
            <ScrollableSections
              customKey="taxAssessmentsImpounds"
              sections={[
                {
                  index: 0,
                  header: <Heading>Tax Assessments and Impounds</Heading>,
                  body: (
                    <Box>
                      <TaxAssessmentsTable
                        selectedProperty={selectedProperty}
                      />
                    </Box>
                  )
                }
              ]}
              navigationPath={navigationPath}
            />
          </Box>
          <Box bgColor="white">
            <ScrollableSections
              customKey="propertyAssessmentsImpounds"
              sections={[
                {
                  index: 0,
                  header: <Heading>Property Assessments and Impounds</Heading>,
                  body: (
                    <PropertyAssessmentsTable
                      selectedProperty={selectedProperty}
                    />
                  )
                }
              ]}
              navigationPath={navigationPath}
            />
          </Box>
          <Box bgColor="white">
            <SectionsAfterTables
              selectedProperty={selectedProperty}
              navigationPath={navigationPath}
            />
          </Box>
          <Box bgColor="white">
            <ConstructionManagementSections
              selectedProperty={selectedProperty}
              navigationPath={navigationPath}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default PropertyPageForm;
