import {
  BooleanSelectType,
  FieldType,
  PartyType,
  TaxpayerIdentifierType
} from "@elphi/types";

import {
  LimaBasePartyFields,
  LimaCommonParty,
  LimaEntityParty,
  LimaEntityPartyFields,
  LimaIndividualParty,
  LimaIndividualPartyFields,
  LimaTINIndividualPartyFields
} from "@elphi/types/entities/lima/party/limaParty.types";
import {
  EntityFormFieldSpecs,
  SpecsBaseOmit
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createOptionsFromEnum } from "../../../../../components/utils/formUtils";
import {
  baseSchemaCommonPartyFieldSpecs,
  baseSchemaEntityPartyFieldSpecs,
  baseSchemaIndividualPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import { mergeSpecs } from "../../../utils/formsSpec.utils";

export type LimaSchemaCommonPartyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<LimaCommonParty<PartyType, TaxpayerIdentifierType>>
>;

export type LimaSchemaIndividualPartyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<LimaIndividualParty>
>;

export type LimaSchemaEntityPartyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<LimaEntityParty>
>;

export const limaCommonPartyFieldSpecs: EntityFormFieldSpecs<LimaBasePartyFields> =
  {
    CRMId: {
      fieldKey: ["CRMId"],
      fieldType: FieldType.String,
      label: "CRM ID"
    }
  };

const limaIndividualPartyFieldSpecs: EntityFormFieldSpecs<
  LimaIndividualPartyFields & LimaTINIndividualPartyFields
> = {
  PaymentTokenId: {
    fieldKey: ["PaymentTokenId"],
    fieldType: FieldType.String,
    label: "Payment Token ID"
  },
  OverridePartyValidationSettingsIndicator: {
    fieldKey: ["OverridePartyValidationSettingsIndicator"],
    fieldType: FieldType.SingleSelect,
    label: "Override Party Validation Settings?",
    options: createOptionsFromEnum(BooleanSelectType)
  }
};

const limaEntityPartyFieldSpecs: EntityFormFieldSpecs<LimaEntityPartyFields> = {
  OverridePartyValidationSettingsIndicator: {
    fieldKey: ["OverridePartyValidationSettingsIndicator"],
    fieldType: FieldType.SingleSelect,
    label: "Override Party Validation Settings?",
    options: createOptionsFromEnum(BooleanSelectType)
  }
};

export const limaCommonPartySpecs: LimaSchemaCommonPartyFieldSpecs = mergeSpecs(
  baseSchemaCommonPartyFieldSpecs,
  limaCommonPartyFieldSpecs
);

export const limaIndividualPartySpecs: LimaSchemaIndividualPartyFieldSpecs =
  mergeSpecs(
    baseSchemaIndividualPartyFieldSpecs,
    limaCommonPartySpecs,
    limaIndividualPartyFieldSpecs
  );
export const limaEntityPartySpecs: LimaSchemaEntityPartyFieldSpecs = mergeSpecs(
  baseSchemaEntityPartyFieldSpecs,
  limaCommonPartySpecs,
  limaEntityPartyFieldSpecs
);

export const fullLimaSchemaEntityPartySpecs = createSpecWithFieldMeta({
  spec: limaEntityPartySpecs
});
export const fullLimaSchemaIndividualPartySpecs = createSpecWithFieldMeta({
  spec: limaIndividualPartySpecs
});
