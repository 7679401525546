import { LimaIndividualParty } from "@elphi/types/entities/lima/party/limaParty.types";
import { OnChangeInput } from "../../../../../components/form-builder/FormBuilder";
import {
  backgroundReportDate,
  backgroundReportExpirationDate,
  creditReportNotes,
  individualBirthDate,
  individualCitizenshipCountry,
  individualContactPointRoleType,
  individualFirstName,
  individualGovernmentIssuedIDExpirationDate,
  individualLastName,
  individualMiddleName,
  individualUSCitizenshipIndicator,
  loanAmountInProcess,
  locExpirationDateAggregation,
  locStatusType,
  operationsDepartment,
  partyContactPointEmailValue,
  partyContactPointTelephoneValue,
  totalApprovedLOC,
  totalStatementQualifyingBalance
} from "../../../base/inputs/party.inputs";
import { buildSection } from "../../../utils/formBuilder.utils";
import {
  individualCrmId,
  individualPaymentTokenId
} from "../../inputs/party.inputs";
import { limaSpecs } from "../../spec-files/limaFieldsSpecs";
import { LimaSections } from "../../types";

const individualInfoSection = (r: {
  state: Partial<LimaIndividualParty>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Individual Info",
    inputs: [
      individualFirstName,
      individualMiddleName,
      individualLastName,
      individualBirthDate,
      individualGovernmentIssuedIDExpirationDate,
      backgroundReportDate,
      backgroundReportExpirationDate,
      individualCitizenshipCountry,
      individualUSCitizenshipIndicator,
      individualContactPointRoleType,
      partyContactPointTelephoneValue,
      partyContactPointEmailValue,
      operationsDepartment,
      totalStatementQualifyingBalance,
      individualPaymentTokenId,
      individualCrmId,
      creditReportNotes,
      locStatusType,
      totalApprovedLOC,
      loanAmountInProcess,
      locExpirationDateAggregation
    ],
    fieldSpecs: limaSpecs.party.entitySpecs
  });

export const individualPartySectionBuilders: LimaSections["party"] = {
  individualInfoSection
};
