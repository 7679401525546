import { ElphiEntityType } from "@elphi/types";
import { merge } from "lodash";
import { creditReportFieldBaseSpecs } from "../../../../components/form-builder/field-specs/credit-report/credit-report.fields";
import { baseElphiFieldsSpecs } from "../../base/spec-files/baseElphiFieldsSpecs";
import { LimaSpecs } from "../types";
import { fullLimaDealSpecs } from "./deal/deal.fields";
import {
  fullLimaSchemaEntityPartySpecs,
  fullLimaSchemaIndividualPartySpecs,
  limaCommonPartySpecs
} from "./party/party.fields";
import { fullLimaPropertySpecs } from "./property/property.fields";

export const limaSpecs: LimaSpecs = {
  ...baseElphiFieldsSpecs,
  [ElphiEntityType.deal]: {
    entitySpecs: fullLimaDealSpecs,
    specsParts: {}
  },
  [ElphiEntityType.property]: {
    entitySpecs: fullLimaPropertySpecs,
    specsParts: {}
  },
  [ElphiEntityType.party]: {
    entitySpecs: merge(
      {},
      limaCommonPartySpecs,
      fullLimaSchemaEntityPartySpecs,
      fullLimaSchemaIndividualPartySpecs
    ),
    specsParts: {
      basePartyFieldSpecs: limaCommonPartySpecs,
      entityPartyFieldSpecs: fullLimaSchemaEntityPartySpecs,
      individualPartyFieldSpecs: fullLimaSchemaIndividualPartySpecs
    }
  },
  [ElphiEntityType.creditScore]: {
    entitySpecs: creditReportFieldBaseSpecs,
    specsParts: {}
  }
};
