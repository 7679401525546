import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { merge, omit } from "lodash";

type MergeReturnType<T extends object[]> = T extends [
  infer U extends object,
  ...infer R extends object[]
]
  ? U & MergeReturnType<R>
  : {};

export const omitFromBaseSchema = <T extends object>(r: {
  obj: T;
  paths: DotNestedKeys<T>[];
}) => {
  return omit(r.obj, r.paths);
};

export const mergeSpecs = <T extends object[]>(
  ...objects: T
): MergeReturnType<T> => {
  return merge({}, ...objects);
};
