import { Deal } from "@elphi/types";
import { BaseSchemaProperty } from "@elphi/types/entities/base/property/baseProperty.types";
import {
  hideFixNFlip,
  hideFixNFlipNoBridge,
  hideRental30,
  hideRental30OrEmpty,
  isEmptyLoanProgramType,
  isFixNFlip,
  isNewConstruction
} from "../../../../components/application/sections/sections.utils";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import {
  PSAExpirationDate,
  allocatedLoanAmount,
  annualHomeownersAssociationFeeAmount,
  annualTaxAmount,
  appraisalCompletedDate,
  appraisalEffectiveDate,
  appraisalExpirationDate,
  appraisalForm1007RequiredIndicator,
  appraisalManagementCompanyName,
  appraisalManagementContactPointEmailValue,
  appraisalManagementContactPointTelephoneValue,
  appraisalManagementRepresentativeFullName,
  appraisalOrderedDate,
  appraisalType,
  appraisalValueDerivationType,
  assignmentFeeAmount,
  borrowerRenovationCostsAfterPurchaseAmount,
  conditionRatingType,
  condoWarrantableIndicator,
  constructionBudgetAssessment,
  constructionCostAmount,
  constructionCostComments,
  contractClosingDate,
  financedUnitCount,
  inspectionDocumentsReceivedDate,
  inspectionInitialDelayReasonStatusType,
  inspectionInitialRequestDate,
  inspectionOrderProcessedDate,
  inspectionOrderReceivedDate,
  inspectionOrderType,
  inspectionReportDueDate,
  inspectorFullName,
  leaseStatusType,
  leaseStrategy,
  legalDescription,
  ltcRatePercent,
  originalPurchaseDate,
  originalPurchasePriceAmount,
  outstandingLoanPayoffAmount,
  parcelNumber,
  payoffExpirationDate,
  pricingEngineLTARVRatePercent,
  propertyAccessInformation,
  propertyAddressLineText,
  propertyAddressUnitIdentifier,
  propertyAreaValue,
  propertyBlockIdentifier,
  propertyCityName,
  propertyCountyName,
  propertyLoanPurpose,
  propertyLotIdentifier,
  propertyPostalCode,
  propertyRightsOwnershipType,
  propertyStateCode,
  propertyStateName,
  propertyStructureBuiltYear,
  propertySubdivisionIdentifier,
  propertyType,
  propertyValuationAmount,
  propertyZoningComplianceRatingType,
  propertyZoningDescription,
  psaAssignmentContractIndicator,
  psaExtensionRequestedIndicator,
  purchasePriceAmount,
  rentalLTVPercent,
  specificZoningClassification,
  subjectToPropertyValuationAmount,
  totalBathroomCount,
  totalBedroomCount,
  totalCostAmount
} from "../inputs/property.inputs";
import {
  BaseSchemaPropertySections,
  BaseSchemaSectionsBuilders
} from "../types";

const propertyDescriptionSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaProperty>;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Description",
      inputs: [
        propertyAddressLineText,
        propertyAddressUnitIdentifier,
        propertyCityName,
        propertyCountyName,
        propertyStateName,
        propertyStateCode,
        propertyPostalCode,
        propertyType,
        financedUnitCount,
        parcelNumber,
        legalDescription,
        propertyLotIdentifier,
        propertyBlockIdentifier,
        propertySubdivisionIdentifier,
        propertyRightsOwnershipType,
        condoWarrantableIndicator
      ],
      fieldSpecs
    });

const propertyDetailSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaProperty>;
    dealState?: Deal;
    onChange?: (v: OnChangeInput) => void;
  }): Section => {
    const dealState = r.dealState || {};
    return buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Details",
      inputs: [
        propertyLoanPurpose,
        purchasePriceAmount,
        outstandingLoanPayoffAmount,
        allocatedLoanAmount,
        addRuleToInput({
          input: rentalLTVPercent,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: pricingEngineLTARVRatePercent,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideFixNFlipNoBridge(dealState)
            }
          ]
        }),
        ...addRulesToInputs({
          inputs: [constructionCostAmount, constructionCostComments],
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideFixNFlip(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: leaseStatusType,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        originalPurchaseDate,
        originalPurchasePriceAmount,
        borrowerRenovationCostsAfterPurchaseAmount,
        ltcRatePercent,
        ...addRulesToInputs({
          inputs: [annualTaxAmount, annualHomeownersAssociationFeeAmount],
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),

        totalCostAmount,
        addRuleToInput({
          input: leaseStrategy,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: constructionBudgetAssessment,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !isNewConstruction(dealState) &&
                !isFixNFlip(dealState) &&
                !isEmptyLoanProgramType(dealState)
            }
          ]
        })
      ],
      fieldSpecs
    });
  };

const purchaseAndSaleAgreementSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaProperty>;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Purchase and Sale Agreement",
      inputs: [
        contractClosingDate,
        PSAExpirationDate,
        psaExtensionRequestedIndicator,
        psaAssignmentContractIndicator,
        assignmentFeeAmount
      ],
      fieldSpecs
    });

const payoffSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaProperty>;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Payoff",
      inputs: [payoffExpirationDate],
      fieldSpecs
    });

const valuationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaProperty>;
    dealState?: Deal;
    onChange?: (v: OnChangeInput) => void;
  }): Section => {
    const dealState = r.dealState || {};
    return buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Valuation",
      inputs: [
        appraisalValueDerivationType,
        propertyValuationAmount,
        addRuleToInput({
          input: subjectToPropertyValuationAmount,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => !hideRental30OrEmpty(dealState)
            }
          ]
        }),
        appraisalOrderedDate,
        appraisalCompletedDate,
        appraisalEffectiveDate,
        appraisalExpirationDate,
        appraisalType,
        conditionRatingType,
        propertyAreaValue,
        totalBedroomCount,
        totalBathroomCount,
        propertyStructureBuiltYear,
        propertyCountyName,
        specificZoningClassification,
        propertyZoningDescription,
        propertyZoningComplianceRatingType,
        addRuleToInput({
          input: appraisalForm1007RequiredIndicator,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        appraisalManagementRepresentativeFullName,
        appraisalManagementContactPointTelephoneValue,
        appraisalManagementContactPointEmailValue,
        appraisalManagementCompanyName,
        propertyAccessInformation
      ],
      fieldSpecs
    });
  };

const generalSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaProperty>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "General",
      inputs: [
        inspectionInitialRequestDate,
        inspectionReportDueDate,
        inspectionOrderType,
        inspectorFullName,
        inspectionOrderReceivedDate,
        inspectionOrderProcessedDate,
        inspectionDocumentsReceivedDate,
        inspectionInitialDelayReasonStatusType
      ],
      fieldSpecs
    });

const createPropertySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaProperty>;
    options?: { hideAttachedComponent: boolean };
  }): Section =>
    buildSection({
      state: r.state,
      header: "Create New",
      inputs: [
        propertyAddressLineText,
        propertyAddressUnitIdentifier,
        propertyCityName,
        propertyStateName,
        propertyStateCode,
        propertyPostalCode,
        propertyLotIdentifier,
        propertyBlockIdentifier,
        propertySubdivisionIdentifier
      ],
      hideAttachedComponent: r.options?.hideAttachedComponent,
      fieldSpecs
    });

export const propertySectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaPropertySections> =
  {
    propertyDescriptionSection,
    propertyDetailSection,
    purchaseAndSaleAgreementSection,
    payoffSection,
    valuationSection,
    generalSection,
    createPropertySection
  };
