import { BaseSchemaEntityParty } from "@elphi/types/entities/base/party/baseParty.types";
import { FeatureFlag } from "@elphi/utils";
import { OnChangeInput } from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { einInputFormatter } from "../../../../../components/form-builder/formatters/inputs.formatter";
import {
  getTaxIdentifierFormatter,
  getTaxIdentifierValidator
} from "../../../../../components/utils/partyUtils";
import { addRuleToInput, buildSection } from "../../../utils/formBuilder.utils";
import {
  backgroundReportDate,
  backgroundReportExpirationDate,
  businessIncorporationStateCode,
  businessIncorporationStateName,
  businessRegistrationStates,
  entityFullName,
  entityNotes,
  entityType,
  evidenceOfGoodStandingExpirationDate,
  fixAndFlipBridgePlusTier,
  groupId,
  newConstructionEligibilityIndicator,
  operationsDepartment,
  partyContactPointEmailValue,
  partyContactPointTelephoneValue,
  partyMilestone,
  realEstateOperationType,
  representativeCreditScore,
  taxpayerIdentifierType,
  taxpayerIdentifierValue
} from "../../inputs/party.inputs";
import {
  BaseSchemaEntityPartySections,
  BaseSchemaSectionsBuilders
} from "../../types";

const partyMilestoneSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaEntityParty>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Party Milestone",
      inputs: [partyMilestone],
      fieldSpecs
    });

const entityTaxIdentifierSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaEntityParty>;
    onChange: (v: OnChangeInput) => void;
    options?: {
      gates?: Partial<{ [key in FeatureFlag]: boolean }>;
    };
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Identifier",
      inputs: [
        taxpayerIdentifierType,
        addRuleToInput({
          input: taxpayerIdentifierValue,
          state: r.state,
          rules: [
            {
              field: "formatter",
              formatter: () =>
                getTaxIdentifierFormatter(r.state?.TaxpayerIdentifierType)
            },
            {
              field: "validation",
              validation:
                getTaxIdentifierValidator(r.state?.TaxpayerIdentifierType) ||
                (() => false)
            }
          ]
        }),
        addRuleToInput({
          input: groupId,
          rules: [
            {
              field: "isHidden",
              predicate: (_) =>
                !r.options?.gates?.esd_2826_organization_management
            }
          ],
          state: r.state
        })
      ],
      fieldSpecs
    });

const entityInfoSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaEntityParty>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Entity Info",
      inputs: [
        entityType,
        entityFullName,
        partyContactPointEmailValue,
        partyContactPointTelephoneValue,
        representativeCreditScore,
        businessIncorporationStateName,
        businessIncorporationStateCode,
        businessRegistrationStates,
        fixAndFlipBridgePlusTier,
        newConstructionEligibilityIndicator,
        realEstateOperationType,
        operationsDepartment,
        entityNotes,
        backgroundReportDate,
        backgroundReportExpirationDate,
        evidenceOfGoodStandingExpirationDate
      ],
      fieldSpecs
    });

const createEntitySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaEntityParty>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "",
      inputs: [
        entityFullName,
        { ...taxpayerIdentifierValue, formatter: einInputFormatter }
      ],
      hideAttachedComponent: true,
      fieldSpecs
    });

export const entityPartySectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaEntityPartySections> =
  {
    partyMilestoneSection,
    entityTaxIdentifierSection,
    entityInfoSection,
    createEntitySection
  };
