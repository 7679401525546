import { Box, Text } from "@chakra-ui/react";
import { DealCalendarType } from "@elphi/types";
import { values } from "lodash";
import { useEffect } from "react";
import { EMPTY } from "../../../../../constants/common";
import { estimatedClosingDate } from "../../../../../forms/schemas/base/inputs/deal.inputs";
import { getSpecs } from "../../../../../forms/schemas/factories/specsFactory";
import { buildSection } from "../../../../../forms/schemas/utils/formBuilder.utils";
import useDealHooks from "../../../../../hooks/deal.hooks";
import { useDealFormHandler } from "../../../../../hooks/dealform.hooks";
import { UpdateMilestoneComponent } from "../../../../deal/UpdateMilestoneComponent";
import FormBuilder, { Section } from "../../../../form-builder/FormBuilder";
import DealUserAssignmentContainer from "../../../../los-user/DealUserAssignmentContainer";

export type DealDataSectionProps = {
  dealCalendar: DealCalendarType;
};

export const DealDataSection = ({ dealCalendar }: DealDataSectionProps) => {
  const { setSelectedDeal, getDeal } = useDealHooks();
  const { dealState, dealOnChange } = useDealFormHandler(dealCalendar.id!);
  const fieldsSpecs = getSpecs();
  useEffect(() => {
    getDeal(dealCalendar.id!, true);
    setSelectedDeal(dealCalendar.id!);
    return () => {
      setSelectedDeal(EMPTY);
    };
  }, [dealCalendar.id]);

  const section: Section = buildSection({
    state: dealState,
    onChange: dealOnChange,
    fieldSpecs: fieldsSpecs.deal?.entitySpecs || {},
    inputs: [estimatedClosingDate],
    header: EMPTY
  });

  return (
    <Box>
      <Box>
        <Text pl="3px" fontWeight="bold">
          Milestone
        </Text>
        <UpdateMilestoneComponent
          dealId={dealCalendar.id!}
          options={{
            showTitle: false,
            showSnapshot: false,
            boxContainer: {
              pl: 0,
              mt: 0
            }
          }}
        />
      </Box>
      <Box ml="-10px">
        <FormBuilder
          customKey="dealDataSection"
          onChange={dealOnChange}
          sections={[section]}
          elphiView="form"
          size={{ minW: "200px" }}
        />
      </Box>
      <Box>
        <Text pl="3px" fontWeight="bold">
          User Assignment
        </Text>
        <DealUserAssignmentContainer
          userAssignmentIds={values(dealCalendar?.assignedUsers)}
          dealId={dealCalendar.id!}
          loanIdentifier={dealCalendar?.loanIdentifier}
        />
      </Box>
    </Box>
  );
};
