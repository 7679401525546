import { DealAggregation } from "../aggregations";
import { BaseEntity } from "../db/baseEntity.types";
import {
  AmortizationType,
  ARMRepriceLookbackType,
  ARMRepriceRoundingMethodType,
  ARMRepriceRoundingRatePercentType,
  CapitalStructureType,
  ChannelType,
  CollateralPackageStatusType,
  DealMilestoneType,
  ExecutionType,
  FundingShieldStatusType,
  IndexSourceType,
  InterestAccrualMethodType,
  LenderEntityType,
  LenderIdentifierType,
  LoanPaymentType,
  LoanProgramType,
  LoanPurposeType,
  PaymentDueDayType,
  PricingEngineExceptionStatusType,
  PropertyManagerType,
  PropertyModelType,
  RealEstateProgramType,
  RecourseType,
  ShippingCompanyType,
  TitleVestingType,
  UnderwriterApprovalStatusType,
  WarehouseBankStatusType,
  WarehouseBankType
} from "./deal.enums.types";
import { DealQuote } from "./deal.quote.types";
import { DealPartyRelation } from "./dealParty.types";
import { DealUser } from "./dealUser.types";
import { BooleanSelectType } from "./fieldFormat.types";
import { BaseAddress, StateName } from "./location.types";
import { Party } from "./party.types";
import {
  FloodInsurancePremiumPaymentType,
  PropertyInsurancePremiumPaymentType,
  PropertyRightsOwnershipType
} from "./property.enums.types";
import {
  FloodInsuranceAgency,
  Property,
  PropertyInsuranceAgency
} from "./property.types";
import { IntegrationType } from "./task-types";
import { WireInsuranceVendorType } from "./task-types/integrations/wire-insurance-certificate/wireInsuranceVendor.types";

/** @deprecated
 * DO NOT USE THIS FILE- USE THE CUSTOMER-SPECIFIC 'Deal' TYPES INSTEAD.
 */

type FieldExceptionValues = {
  ExceptionType: string[];
  PolicySection: string[];
  ExceptionCommentary: string;
  ExceptionApprovedBy: string;
};

export type BaseMilestoneData = {
  type: DealMilestoneType;
  timestamp: string;
};

type MilestoneChangeReasonData = BaseMilestoneData & {
  genericReason: string;
  specificReason: string;
};
type DealLedger = {
  AppraisalDepositAmount: string;
  AppraisalFeeAmount: string;
  CreditReportFeeAmount: string;
  InterestReserveAmount: string;
  NewYorkProcessingFeeAmount: string;
  LenderCounselFeeAmount: string;
  ContractorReviewFeeAmount: string;
  FeasibilityReviewFeeAmount: string;
  CondoCertificationFeeAmount: string;
};

type CreditReviewCommittee = {
  creditMemoDraftedDate: string;
  creditMemoApprovedDate: string;
  internalSubmissionDate: string;
  internalApprovalDate: string;
  externalSubmissionDate: string;
  externalApprovalDate: string;
  lOIIssuedDate: string;
  lOIAcceptedDate: string;
};

export type ChangeReasonMilestones =
  | DealMilestoneType.DeadDeal
  | DealMilestoneType.Suspended;

export type MilestoneData<T extends DealMilestoneType> =
  T extends ChangeReasonMilestones
    ? MilestoneChangeReasonData
    : BaseMilestoneData;

export type GenericReasonMap = {
  [key in ChangeReasonMilestones]: { [key: string]: string };
};

export type SpecificReasonMap = {
  [key in ChangeReasonMilestones]: {
    [genericReasonKey: string]: { [key: string]: string };
  };
};

export type DealMetadata = {
  milestone: DealMilestoneType;
  organizations: string[];
  branches: string[];
  teams: string[];
  activeUsers: string[];
  milestoneData?: {
    [P in DealMilestoneType]?: MilestoneData<P>;
  };
};

type IntegrationMetadata = {
  salesforceAccountId: string;
  salesforceDealId: string;
  customerPortalId: string;
};

type Attorney = {
  FullName: string;
};

type TitleCompany = BaseAddress & {
  Attorney: Attorney;
  FullName: string;
  ClosingAgentFirstName: string;
  ClosingAgentLastName: string;
  ClosingAgentContactPointTelephoneValue: string;
  ClosingAgentContactPointEmailValue: string;
  TitleInsuranceUnderwriter: string;
};

type TitleCommitment = {
  TitleEffectiveDate: string;
  TitleExpirationDate: string;
  TitlePolicyAmount: string;
  TitleVestingType: TitleVestingType;
  AllSubjectPropertiesIncludedIndicator: BooleanSelectType;
  AllPayoffsLiensIncludedInSection1RequirementsIndicator: BooleanSelectType;
  RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator: BooleanSelectType;
  TitleInternationalExecutionIndicator: BooleanSelectType;
  LeaseholdEndorsementIndicator: BooleanSelectType;
  TitleCommitmentComments: string;
  PropertyRightsOwnershipType: PropertyRightsOwnershipType;
};

type GeneralContractor = {
  FullName: string;
  RepresentativeFirstName: string;
  RepresentativeLastName: string;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
  CapitalExpenditureAmount: string;
};

type Broker = {
  FullName: string;
  RepresentativeFirstName: string;
  RepresentativeLastName: string;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
  NMLSIdentifier: string;
  BrokerWiringInstructionsIndicator: BooleanSelectType;
};

type PropertyManagement = {
  PropertyManagerType: PropertyManagerType;
  FullName: string;
  RepresentativeFullName: string;
  FullAddressName: string;
  ContactPointTelephoneValue: string;
  ContactPointEmailValue: string;
  PropertyManagementComments: string;
};

type Lender = BaseAddress & {
  FormationState: StateName;
  EntityType: LenderEntityType;
};

type LenderFinance = {
  PartnerFirstName: string;
  PartnerLastName: string;
  PartnerInitialTermSheetApprovalStatusIndicator: BooleanSelectType;
  PartnerInitialTermSheetApprovalStatusDate: string;
  PartnerFinalTermSheetApprovalStatusDate: string;
  BorrowerFinalTermSheetApprovalStatusDate: string;
};

export type DealReadonlyField = Partial<{
  EstimatedClosingDate: string;
  RequestedLoanAmount: string;
  LoanProgramType: LoanProgramType;
}>;

/** @deprecated
 * Use the customer-specific 'Deal' type instead.
 */
export type Deal = BaseEntity<DealFields, DealReadonlyField>;

type DealFields = {
  quote: DealQuote; //support full replace
  aggregations: DealAggregation;
  assignedUsers?: string[];
  DealMetadata: DealMetadata;
  LOCAvailableAmount: string;
  LOCExpirationDate: string;
  CashOutAmount: string;
  DownPaymentAmount: string;
  AggregateOccupancyPercent: string;
  PolicySectionNumber: string;
  LenderIdentifier: LenderIdentifierType;
  LoanIdentifier: string;
  ApplicationReceivedDate: string;
  LOCIssuanceDate: string;
  PreliminaryUnderwritingDecisionDate: string;
  FinalUnderwritingDecision: string;
  InitialTaskGenerationDate: string;
  ClearToCloseDate: string;
  LoanName: string;
  Channel: ChannelType;
  RealEstateProgramType: RealEstateProgramType;
  EmployeeLoanIndicator: string;
  TotalPropertyCount: string;
  RecourseType: RecourseType;
  WireReleaseDate: string;
  LoanFundingDate: string;
  PaymentDueDay: PaymentDueDayType;
  NextPaymentDueDate: string;
  AmortizationType: AmortizationType;
  ExecutionType: ExecutionType;
  LoanPaymentType: LoanPaymentType;
  LoanTermPeriodMonthCount: string;
  LoanAmortizationPeriodMonthCount: string;
  LoanInterestOnlyPeriodMonthCount: string;
  LoanAfterInterestOnlyPeriodMonthCount: string;
  InterestAccrualMethodType: InterestAccrualMethodType;
  LoanAmortizationPeriodYearCount: string;
  IndexSourceType: IndexSourceType;
  CapitalStructureType: CapitalStructureType;
  MarginRatePercent: string;
  FloorRatePercent: string;
  LifetimeCapRatePercent: string;
  ARMRepriceRoundingRatePercentType: ARMRepriceRoundingRatePercentType;
  ARMRepriceRoundingMethodType: ARMRepriceRoundingMethodType;
  ARMRepriceLookbackType: ARMRepriceLookbackType;

  UCCExpirationDate: string;
  DefaultInterestRatePercent: string;
  WarehouseBankType: WarehouseBankType;
  WarehouseBankStatusType: WarehouseBankStatusType;
  FundingShieldStatusType: FundingShieldStatusType;
  HaircutAmount: string;
  PostClosingNotes: string;
  CollateralPackageStatusType: CollateralPackageStatusType;
  CollateralPackageTrackingNumber: string;
  Ledger: DealLedger;

  LienPosition: string;
  UCCFiledIndicator: BooleanSelectType;
  TitleCompany: TitleCompany;
  BlendedLTCLoanAmount: string;
  ConstructionLTCRatePercent: string;
  TotalPropertyValuationAmount: string;
  MaxARVAmount: string;
  InitialAdvancedFundAmount: string;
  TotalLoanFeesAndClosingCostAmount: string;
  ClosingAnalystUserId: string;
  PromissoryNoteComments: string;
  ShippingCompanyType: ShippingCompanyType;
  NoteToWarehouseTrackingNumber: string;
  SettlementStatementFileNumber: string;
  PersonalGuarantorsCount: string;
  PowerOfAttorneyFullName: string;
  AllongeOrAssignmentSentToWarehouseIndicator: BooleanSelectType;
  GeneralContractor: GeneralContractor;
  Broker: Broker;
  Lender: Lender;
  PropertyModelType: PropertyModelType;
  PropertyManagement: PropertyManagement;
  IntegrationMetadata: IntegrationMetadata;
  LastPaymentDueDate: string;
  GrossSpreadPercent: string;
  GracePeriodDays: string;
  LetterOfIntentSignedDate: string;
  ReleasePercent: string;
  LateFeePercent: string;
  Exceptions: FieldExceptionValues;
  PromissoryNoteRequestedIndicator: BooleanSelectType;
  PromissoryNoteShippedIndicator: BooleanSelectType;
  PromissoryNoteTrackingNumber: string;
  AllongeAssignmentSentToWarehouseIndicator: BooleanSelectType;
  AllongeAssignmentTrackingNumber: string;
  NotaryUserId: string;
  NotaryExpirationDate: string;
  LoanPackageTrackingNumber: string;
  LoanPackageNoteTrackingNumber: string;
  FundingAndDisbursementApprovalIndicator: BooleanSelectType;
  LoanPackageInternationalExecutionIndicator: BooleanSelectType;
  LoanPackageNoteReceivedByLenderIndicator: BooleanSelectType;
  LoanPackageChecksReceivedIndicator: BooleanSelectType;
  LoanPackageCheckNumber: string;
  LoanPackageCheckAmount: string;
  UnderwriterApprovalStatus: UnderwriterApprovalStatusType;
  UnderwriterApprovalComments: string;
  AssetSummaryReportIndicator: BooleanSelectType;
  CreditMemorandumIndicator: BooleanSelectType;
  CreditReviewCommitteeApprovalIndicator: BooleanSelectType;
  ProgramDirectorApprovalIndicator: BooleanSelectType;
  ProgramDirectorComments: string;
  SubmissionNotes: string;
  PricingEngineExceptionStatus: PricingEngineExceptionStatusType;
  ChargentTransactionIdentifier: string;
  ChargentGatewayIdentifier: string;
  CRMId: string;
  OperationsDepartment: string;
  ChargentCollectionAmount: string;
  ValuationFeesCollectedBySalesIndicator: BooleanSelectType;
  InitialLTVRatePercent: string;
  PropertyInsurancePremiumPaymentType: PropertyInsurancePremiumPaymentType;
  FloodInsurancePremiumPaymentType: FloodInsurancePremiumPaymentType;
  PropertyInsuranceAgency: Pick<PropertyInsuranceAgency, "FullName">;
  FloodInsuranceAgency: Pick<FloodInsuranceAgency, "FullName">;
  InterestReserveDepositAmount: string;
  DeferredMaintenanceAmount: string;
  TitleCommitment: TitleCommitment;
  TotalLiquidAssetAmount: string;
  SettlementStatementMatchLedgerIndicator: BooleanSelectType;
  LoanAmountVerifiedMatchIndicator: BooleanSelectType;
  ConstructionBudgetVerifiedMatchIndicator: BooleanSelectType;
  SettlementStatementComments: string;
  EOExpirationDate: string;
  EONamedInsuredMatchIndicator: BooleanSelectType;
  WireABARoutingNumber: string;
  WireAccountNumber: string;
  WireBankName: string;
  WireAccountName: string;
  TitleFormsComments: string;
  PortfolioLoanIndicator: BooleanSelectType;
  LoanPurposeType: LoanPurposeType;
  RateLockStartDate: string;
  RateLockEndDate: string;
  TotalCashToFromBorrower: string;
  CashFromBorrowerAmount: string;
  CashToBorrowerAmount: string;
  MarketingPromotionCreditAmount: string;
  MarketingPromotionCode: string;
  BuildingEnvelopeExpandedIndicator: BooleanSelectType;
  LoadBearingWallsRemoveIntendedIndicator: BooleanSelectType;
  SquareFootageAddedIndicator: BooleanSelectType;
  LenderFinance: LenderFinance;
  ExitStrategy: string;
  TargetCloseDate: string;
  ApplicationRequestedLoanAmount: string;
  ProjectAnticipatedCompletionTimeline: string;
  ConstructionBudgetRemainingAmount: string;
  VerifiableConstructionCompletedAmount: string;
  LienDollarAmount: string;
  PropertyTypeChangingIndicator: string;
  PropertyZoningChangesIndicator: string;
  SubdividePropertyPartialReleasesIndicator: string;
  LenderTitleProviderIndicator: string;
  LenderPropertyInsuranceProviderIndicator: string;
  HOAContactName: string;
  HOAContactPhone: string;
  HOAContactEmail: string;
  Occupancy: string;
  PropertyRentReadyConditionIndicator: string;
  PropertyLeasePurchaseOptionIndicator: string;
  OccupationIndicator: string;
  SellerRelationshipIndicator: string;
  AssociatedPropertiesDescription: string;
  DevelopmentStrategy: string;
  LotStatus: string;
  CurrentLotMarketValueAmount: string;
  LotZonedParcelIdIndicator: string;
  BuildingPermitsIndicator: string;
  BuildingPermitsExpectedDate: string;
  LotUtilitiesIndicator: string;
  PrimaryBorrowers: string[];
  SecondaryBorrowers: string[];
  PrimarySponsors: string[];
  SecondarySponsors: string[];
  Integrations: UnionDealIntegrationFields;
  CreditReviewCommittee: CreditReviewCommittee;
  ACHElectedIndicator: BooleanSelectType;
  SellerHUDAddedIndicator: BooleanSelectType;
  PurchaserWarrantyDeedAddedIndicator: BooleanSelectType;
  InterestReserveEscrowOverrideMonthCount: string;
  InterestReserveEscrowOverrideAmount: string;
  ProcessingFeeOverride: string;
  mailAwayIndicator: BooleanSelectType;
  fundingAgentEmailAddress: string;
  WireInsuranceMatchDealIndicator: string;
  RecordedMortgageReceivedDate: string;
  TitlePolicyReceivedDate: string;
  RecordedWarrantyDeedReceivedDate: string;
  AppraisalNotes: string;
};

export type DealTableRowData = {
  parties: Party[];
  dealUser: DealUser[];
  properties: Property[];
  dealParty: DealPartyRelation[];
  deals: Deal[];
};

export type FundingShieldIntergration = {
  [IntegrationType.WireInsuranceCertificate]: {
    selectedVendor?: WireInsuranceVendorType.FundingShield;
    [WireInsuranceVendorType.FundingShield]: {
      userEmail: string;
      userFirstName: string;
      userLastName: string;
      rushOrderIndicator: BooleanSelectType;
    };
  };
};

export type UnionDealIntegrationFields = FundingShieldIntergration;
