import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { LoanProgramType, StatusCode } from "@elphi/types";
import { BusinessFeatureFlag } from "@elphi/utils";
import lodash from "lodash";
import { useNavigate } from "react-router-dom";
import { GateKeeper } from "../../features/gate/GateKeeper";
import { WorksheetTab } from "../../features/worksheet/worksheet.types";
import { getSpecs } from "../../forms/schemas/factories/specsFactory";
import useDealHooks from "../../hooks/deal.hooks";
import { RECORD_NOT_EXISTS_RESPONSE } from "../../redux/v2/deal";
import { UpdateMilestoneComponent } from "../deal/UpdateMilestoneComponent";
import { SnapshotModalContainer } from "../snapshots/SnapshotModal";
import { useElphiToast } from "../toast/toast.hook";
import { WorksheetIcon } from "./WorksheetIcons";

export type WorksheetSidebarProps = {
  setCurrentTab: (tab: WorksheetTab) => void;
  currentTab: WorksheetTab;
  tabs: { value: WorksheetTab; label: string; path: string }[];
  dealId: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  snapshotId?: string | undefined;
};

const WorksheetSidebar = (props: WorksheetSidebarProps) => {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose, snapshotId } = props;
  const {
    updateRateLock,
    postRateLock,
    getRateLock,
    updateDealQuote,
    getDealQuote,
    getDealQuoteData,
    updateDealQuoteResponse,
    getDealQuoteResponse,
    getDealQuoteDataResponse,
    dealState
  } = useDealHooks();
  const { errorToast, successToast } = useElphiToast();
  const fieldsSpecs = getSpecs();
  const updateQuoteIsLoading =
    updateDealQuoteResponse.isLoading ||
    getDealQuoteResponse.isLoading ||
    getDealQuoteDataResponse.isLoading;

  const updatePricingEngine = async () => {
    if (updateQuoteIsLoading) return;
    const dealQuoteData = await getDealQuoteData({ dealId: props.dealId });
    if (dealQuoteData.status !== StatusCode.OK) {
      errorToast({
        title: "Quote Information NOT Updated",
        description: dealQuoteData.data.description
      });
      return;
    }
    const currentDeal = dealState.entities[props.dealId];

    const loanProgram = getFocusedData(
      currentDeal && currentDeal?.aggregations?.LoanProgramType
    ) as LoanProgramType;
    const loanProgramPricingEngine = dealQuoteData.data.quoteInfo.program;
    const doesLoanProgramMatch = () => {
      if (!loanProgram) return false;
      if (
        loanProgramPricingEngine === "STF" &&
        [LoanProgramType.Rental30, LoanProgramType.Rental30Premier].includes(
          loanProgram
        )
      )
        return false;
      if (
        loanProgramPricingEngine === "Rental" &&
        [
          LoanProgramType.NewConstruction,
          LoanProgramType.FixNFlip,
          LoanProgramType.BridgePlus
        ].includes(loanProgram)
      )
        return false;
      return true;
    };
    if (!doesLoanProgramMatch()) {
      errorToast({
        title: "Loan Program Doesn't Match Pricing Engine's program",
        description:
          "Please make sure you are getting the correct dealQuote type"
      });
      return;
    }

    const quoteId = getFocusedData(currentDeal?.quote?.aggregations?.QuoteId);
    const startDate = currentDeal?.RateLockStartDate;
    const endDate = currentDeal?.RateLockEndDate;
    if (quoteId && startDate && endDate) {
      const currentRateLock = await getRateLock({
        id: quoteId
      });

      if (currentRateLock.status === StatusCode.OK) {
        if (currentRateLock.data.response !== RECORD_NOT_EXISTS_RESPONSE) {
          await updateRateLock({
            quoteId,
            startDate,
            endDate
          }).then((r) => {
            if (r.status !== StatusCode.OK) {
              errorToast({
                title: "Rate Lock Failed",
                description: r.data.description
              });
            }
          });
        } else {
          await postRateLock({
            quoteId,
            startDate,
            endDate
          }).then((r) => {
            if (r.status !== StatusCode.OK) {
              errorToast({
                title: "Rate Lock Failed",
                description: r.data.description
              });
            } else if (r.data?.response === RECORD_NOT_EXISTS_RESPONSE) {
              errorToast({
                title: `Failed to create rate lock`,
                description: `Quote ${quoteId} may not have been created yet`
              });
            }
          });
        }
      } else {
        errorToast({
          title: "Rate Lock Failed",
          description: `Failed to get Rate Lock for Quote ID: ${quoteId}`
        });
      }
    } else {
      errorToast({
        title: "Rate Lock Failed",
        description:
          "Rate Lock Start Date and Rate Lock End Date were not updated because they do not contain a value"
      });
    }

    await updateDealQuote({
      id: props.dealId,
      dealQuoteData: dealQuoteData.data
    }).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Quote Information Updated",
          description: "Awaiting pricing engine fetch..."
        });
      } else {
        errorToast({
          title: "Quote Information NOT Updated",
          description: r.data.description
        });
      }
    });

    await getDealQuote({ dealId: props.dealId }).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Quote Received",
          description: `Quote Id: ${getFocusedData(
            r.data?.deal?.quote?.aggregations?.QuoteId
          )}`
        });
        if (r.data?.pricingValidationErrors) {
          const jsonError = JSON.parse(r.data.pricingValidationErrors) as {
            [key: string]: {
              path: string;
              message: string | undefined;
              params: Record<string, any>[];
            };
          };
          const fieldPaths = Object.values(jsonError).map((e) => {
            const path = e.path;
            const noLeadingSlash = path[0] === "/" ? path.substring(1) : path;
            return noLeadingSlash.split("/");
          });

          const fieldNames = fieldPaths.map((path) => {
            return lodash.get(fieldsSpecs.deal?.entitySpecs, path)?.label || "";
          });
          errorToast({
            title: "Some Pricing Engine Outputs Were Not Retrieved",
            description: fieldNames.join(", ")
          });
        }
      } else {
        errorToast({
          title: "Quote Information Not Received",
          description: r.data.description
        });
      }
    });
  };

  return (
    <Box h="100%">
      <Flex
        flexDir={"column"}
        bgColor={"#F2F7FF"}
        h="100%"
        w={!isOpen ? "40px" : "100%"}
      >
        <Box>
          <IconButton
            bgColor="transparent"
            onClick={() => (isOpen ? onClose() : onOpen())}
            aria-label="application-sidebar-toggle-btn"
            icon={<HamburgerIcon />}
          />
        </Box>

        {!isOpen && (
          <Box>
            {props.tabs.map((t, i) => {
              return (
                <Tooltip
                  key={i}
                  aria-label="closed-drawer-tab"
                  label={t.value}
                  placement="right"
                >
                  <Box
                    key={i}
                    pl="5px"
                    pt="10px"
                    pr="5px"
                    onClick={() => {
                      props.setCurrentTab(t.value);
                      navigate(t.path, {
                        state: { title: t.label }
                      });
                    }}
                  >
                    <Box
                      cursor={"pointer"}
                      bgColor={
                        props.currentTab === t.value ? "#DBE7FF" : "none"
                      }
                      borderRadius="8px"
                    >
                      <Center p="10px">
                        <WorksheetIcon
                          color={
                            props.currentTab === t.value
                              ? "#0157FE"
                              : "gray.600"
                          }
                          tab={t.value}
                        />
                      </Center>
                    </Box>
                  </Box>
                </Tooltip>
              );
            })}
            <GateKeeper gate={BusinessFeatureFlag.Update_Quote}>
              <Tooltip
                aria-label="closed-drawer-tab"
                label={"Update Quote"}
                placement="right"
              >
                <Box
                  borderRadius="8px"
                  onClick={updatePricingEngine}
                  bgColor={!!snapshotId ? "gray.200" : "none"}
                  cursor={!!snapshotId ? "not-allowed" : "pointer"}
                  pointerEvents={!!snapshotId ? "none" : "auto"}
                  opacity={!!snapshotId ? 0.5 : 1}
                >
                  <Center p="10px">
                    {!updateQuoteIsLoading ? (
                      <WorksheetIcon
                        color={
                          props.currentTab === "Pricing Engine"
                            ? "#0157FE"
                            : "gray.600"
                        }
                        tab={WorksheetTab.PricingEngine}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </Center>
                </Box>
              </Tooltip>
            </GateKeeper>
            <SnapshotModalContainer dealId={props.dealId} />
          </Box>
        )}
        {isOpen && (
          <Box>
            <Center>
              <Heading m="24px 24px 8px 24px" fontSize="24px">
                Loan Overview
              </Heading>
            </Center>
            <Box pt="30px">
              {props.tabs.map((t, i) => {
                return (
                  <Box key={i} pr="15px" pl="20px">
                    <Box
                      cursor={"pointer"}
                      bgColor={
                        props.currentTab === t.value ? "#DBE7FF" : "none"
                      }
                      borderRadius="8px"
                      p="7px"
                    >
                      <Flex
                        onClick={() => {
                          props.setCurrentTab(t.value);
                          navigate(t.path, {
                            state: { title: t.label }
                          });
                        }}
                      >
                        <Box p="10px 24px 10px 8px">
                          <Text
                            color={
                              props.currentTab === t.value
                                ? "#0157FE"
                                : "gray.600"
                            }
                            fontWeight={"bold"}
                            fontSize="large"
                          >
                            {t.label}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box pt="30px">
              <GateKeeper gate={BusinessFeatureFlag.Update_Quote}>
                <Button
                  bgColor="green.200"
                  _hover={{
                    backgroundColor: !!snapshotId ? "green.200" : "gray.200"
                  }}
                  _active={{
                    backgroundColor: !!snapshotId ? "green.200" : "gray.100"
                  }}
                  color="white"
                  ml="30px"
                  onClick={updatePricingEngine}
                  isLoading={updateQuoteIsLoading}
                  isDisabled={!!snapshotId}
                >
                  Update Quote
                </Button>
              </GateKeeper>
            </Box>
            <Box pt="30px" paddingLeft="10px">
              <UpdateMilestoneComponent
                snapshotId={snapshotId}
                dealId={props.dealId}
              />
            </Box>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default WorksheetSidebar;
