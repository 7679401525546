import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { LoanProgramType } from "@elphi/types";
import { BaseSchemaDeal } from "@elphi/types/entities/base/deal/baseDeal.types";
import { InputBuilderSpecs, Section } from "../../form-builder/FormBuilder";

export const loanProgramTypeIsNotEmpty = (
  loanProgramType: LoanProgramType | undefined
) => !!loanProgramType;

export const hideFixNFlip = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![
          LoanProgramType.FixNFlip,
          LoanProgramType.NewConstruction,
          LoanProgramType.BridgePlus
        ].includes(loanProgramType);
};

export const hideFixNFlipNoBridge = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.FixNFlip, LoanProgramType.NewConstruction].includes(
          loanProgramType
        );
};

const hasMatchingLoanProgramType = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined,
  loanProgramTypesList: LoanProgramType[]
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : !!loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        loanProgramTypesList.includes(loanProgramType);
};

export const isNewConstruction = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  return hasMatchingLoanProgramType(state, [LoanProgramType.NewConstruction]);
};

export const isFixNFlip = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  return hasMatchingLoanProgramType(state, [LoanProgramType.FixNFlip]);
};

export const isRental30Premier = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  return hasMatchingLoanProgramType(state, [LoanProgramType.Rental30Premier]);
};

export const isEmptyLoanProgramType = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideFixNFlipOrEmpty = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return hideFixNFlip(state) || !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideRental30 = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.Rental30, LoanProgramType.Rental30Premier].includes(
          loanProgramType
        );
};

export const hideRental30OrEmpty = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return hideRental30(state) || !loanProgramTypeIsNotEmpty(loanProgramType);
};

export const hideBridgePlus = <T extends BaseSchemaDeal>(
  state: Partial<T> | undefined
) => {
  const loanProgramType = getFocusedData(
    state?.aggregations?.LoanProgramType
  ) as LoanProgramType;

  return state === undefined
    ? false
    : loanProgramType &&
        loanProgramTypeIsNotEmpty(loanProgramType) &&
        ![LoanProgramType.BridgePlus].includes(loanProgramType);
};

export const addGeneralSpecsToSections = (r: {
  sections: Section[];
  specs: Partial<InputBuilderSpecs>;
}): Section[] => {
  r.sections.forEach((s) => {
    s.inputs.forEach((i) => (i.isReadOnly = true));
  });
  return r.sections;
};
