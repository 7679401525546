import {
  BaseEntity,
  IntegrationType,
  TaskType,
  getBaseInsurancePolicy
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { get, merge } from "lodash";
import { NOT_AVAILABLE } from "../../../constants/common";

import { createTemplateWithFieldMeta } from "../../form-builder/field-specs/utils/fieldMeta.utils";
import { isAggregationPath } from "../../task/field-selection/d2v.utils";
import { getTaskTemplate } from "../../task/task-template/templates/task.template";

const removeFields = <T extends object>(e: T, keysToRemove: string[]): T => {
  keysToRemove.forEach((k) => {
    delete e[k];
  });
  return e;
};
const removeBaseFields = <T extends object>(e: T): T => {
  const baseFields: DotNestedKeys<BaseEntity<object>>[] = ["index"];
  return removeFields(e, baseFields);
};

const wireInsuranceTaskTemplate = getTaskTemplate({
  type: TaskType.Integration,
  integrationType: IntegrationType.WireInsuranceCertificate
});
const rentalDataTaskTemplate = getTaskTemplate({
  type: TaskType.Integration,
  integrationType: IntegrationType.RentalData
});
const floodCertificateTaskTemplate = getTaskTemplate({
  type: TaskType.Integration,
  integrationType: IntegrationType.FloodCertificate
});

export const taskFields = createTemplateWithFieldMeta({
  entityTemplate: merge(
    wireInsuranceTaskTemplate,
    rentalDataTaskTemplate,
    floodCertificateTaskTemplate
  )
});

export const insurancePolyFields = createTemplateWithFieldMeta({
  entityTemplate: removeFields(removeBaseFields(getBaseInsurancePolicy()), [
    "entityIds",
    "dealIds",
    "entities",
    "deals",
    "branchRepId"
  ])
});

export const buildLabel = (r: {
  object: object;
  key: string;
  shouldKeepFullPath?: boolean;
}) => {
  const { object: o, key: k } = r;
  const label = get(o, `${k}.label`, NOT_AVAILABLE);
  if (r.shouldKeepFullPath) {
    return `${label}:${k}`;
  }

  const fieldPath = k.split(".");
  const pathWithoutFocus = fieldPath.slice(0, -1).join(".");
  const isAggregation = isAggregationPath(fieldPath);
  return isAggregation ? `${label}:${pathWithoutFocus}` : `${label}:${k}`;
};
