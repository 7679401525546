import {
  AGGREGATIONS,
  AggregationFocusType,
  BaseEntity,
  ElphiEntityType
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { getObjectPaths } from "@elphi/utils";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import { creditReportAggregationPaths } from "../../../form-builder/field-specs/credit-report/credit-report.fields";
import { buildLabel } from "../field-selection.utils";
import {
  FieldMappingDistinctOption,
  FieldSelectionBuilder
} from "./field-selection.builder.types";

const entitiesFieldToOmit: DotNestedKeys<BaseEntity<object>>[] = [
  "fieldMeta",
  "modifiedAt",
  "createdAt"
];

export const fieldSelection: FieldSelectionBuilder = {
  build: (
    entityType?: ElphiEntityType
  ): {
    label: string;
    value: string;
  }[] => {
    const specs = getSpecs();
    const fieldSpecs = entityType && specs?.[entityType];
    return entityType && fieldSpecs
      ? buildOptions({
          entriesToOmit: entitiesFieldToOmit,
          fieldSpecs: fieldSpecs.entitySpecs,
          entityType
        })
      : [];
  }
};

export const buildOptions = (option: FieldMappingDistinctOption) => {
  const paths = getObjectPaths({
    obj: option.fieldSpecs,
    options: {
      fieldsToOmit: option.entriesToOmit
    }
  });

  return paths.map((path) => {
    const isAggregation = isAggregationField({
      path,
      entityType: option.entityType
    });
    const key = isAggregation
      ? `${path}.${AggregationFocusType.Override}`
      : path;
    return {
      label: buildLabel({ object: option.fieldSpecs, key }),
      value: path
    };
  });
};

const isAggregationField = (r: {
  entityType?: ElphiEntityType;
  path: string;
}) => {
  return (
    r.path.includes(AGGREGATIONS) ||
    (r.entityType === ElphiEntityType.creditScore &&
      creditReportAggregationPaths.some((p) => r.path.includes(p)))
  );
};
