import { DealPartyRelation, DealPartyRelationType } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";

import {
  DealToEntityFieldSpecs,
  DealToIndividualFieldSpecs
} from "../../../../components/form-builder/field-specs/deal-party/deal-party.fields";
import { buildSection } from "../../utils/formBuilder.utils";
import { dealPartyRelationRoleType } from "../inputs/dealParty.inputs";
import {
  BaseSchemaDealPartyRelationSections,
  BaseSchemaSectionsBuilders
} from "../types";

const dealPartyRelationSection =
  (specs: {
    dealToEntityFieldSpecs: DealToEntityFieldSpecs;
    dealToIndividualFieldSpecs: DealToIndividualFieldSpecs;
  }) =>
  (r: {
    state: DealPartyRelation;
    onChange: (v: OnChangeInput) => void;
    options: {
      elphiView?: "accordion" | "form";
    };
  }): Section => {
    const { state, onChange, options } = r;
    const type = state?.type;
    const fieldSpecs =
      type === DealPartyRelationType.DealEntity
        ? specs.dealToEntityFieldSpecs
        : specs.dealToIndividualFieldSpecs;
    return buildSection({
      state: r.state,
      onChange,
      header: options.elphiView !== "form" ? "Deal Party Relation" : "",
      inputs: [dealPartyRelationRoleType],
      fieldSpecs
    });
  };

export const dealPartyRelationSectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaDealPartyRelationSections> =
  {
    dealPartyRelationSection
  };
