import {
  AggregationType,
  FloodCertificateVendorType,
  IntegrationType
} from "@elphi/types";
import {
  booleanValidation,
  dateValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../../components/utils/validationUtils";
import { buildInput } from "../../../utils/formBuilder.utils";
import { integrationSpecs } from "../../spec-files/integrationFieldsSpecs";

export const specialFloodHazardAreaIndicator = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.SpecialFloodHazardAreaIndicator
      ?.focused?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: booleanValidation
});

export const floodCheckStatus = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.FloodCheckStatus?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: singleSelectValidation
});

export const orderNumber = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.OrderNumber?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const orderStatus = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.OrderStatus?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: singleSelectValidation
});

export const NFIPMapCommunityNumber = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPMapCommunityNumber?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const NFIPLegalCommunityNumber = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPLegalCommunityNumber
      ?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const NFIPMapCommunityName = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPMapCommunityName?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const NFIPLegalCommunityName = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPLegalCommunityName?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const NFIPMapPanelNumber = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.NFIPMapPanelNumber?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const floodMapDate = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.FloodMapDate?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: dateValidation
});

export const floodZone = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.FloodZone?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const determinationDate = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.DeterminationDate?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: dateValidation
});

export const propertyMSAMD = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.PropertyMSAMD?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const requestedInformation = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.RequestedInformation?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const TSDNComments = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.TSDNComments?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const resultDescription = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.ResultDescription?.focused
      ?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const usersEmailAddress = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink].UsersEmailAddress?.fieldKey,
  validation: stringValidation
});

export const returnDate = buildInput({
  fieldKey:
    integrationSpecs.property?.entitySpecs.aggregations.Integrations?.[
      IntegrationType.FloodCertificate
    ]?.[FloodCertificateVendorType.ServiceLink]?.ReturnDate?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: dateValidation
});
