import { ElphiEntityType, FieldType, LabelValue } from "@elphi/types";
import { getObjectPaths } from "@elphi/utils";
import { get } from "lodash";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import { buildLabel } from "../field-selection.utils";
import {
  FieldMappingOption,
  FieldObjectBuilder,
  FieldSelectionBuilder
} from "./field-selection.builder.types";

export const fieldSelection: FieldSelectionBuilder = {
  build: (
    entityType?: ElphiEntityType
  ): {
    label: string;
    value: string;
  }[] => {
    const specs = getSpecs();
    const fieldSpecs = entityType && specs?.[entityType];
    return entityType && fieldSpecs
      ? buildOptions({ fieldSpecs: fieldSpecs.entitySpecs })
      : [];
  }
};

export const fieldObject: FieldObjectBuilder = {
  build: (entityType: ElphiEntityType) => {
    const specs = getSpecs();
    const fieldSpecs = entityType && specs?.[entityType];
    return entityType && fieldSpecs
      ? buildObjects({ fieldSpecs: fieldSpecs.entitySpecs })
      : [];
  }
};

const buildOptions = (option: FieldMappingOption) => {
  const paths = getObjectPaths({
    obj: option.fieldSpecs,
    options: {
      useAllAggregationOptions: true
    }
  });

  return paths.map((key) => {
    return {
      label: buildLabel({
        object: option.fieldSpecs,
        key,
        shouldKeepFullPath: true
      }),
      value: key
    };
  });
};

const buildObjects = (option: FieldMappingOption) => {
  const paths = getObjectPaths({
    obj: option.fieldSpecs,
    options: {
      useAllAggregationOptions: true
    }
  });

  return paths.map((key) => {
    const specs = option.fieldSpecs;
    const field = get(
      specs,
      key,
      {} as {
        fieldType: FieldType;
        label: string;
        options?: LabelValue[];
      }
    );
    const label = `${field.label || NOT_AVAILABLE}:${key}`;
    return {
      label,
      value: key,
      type: field.fieldType,
      options: field.options
    };
  });
};
