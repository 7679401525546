import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  IconButton,
  Link,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { Deal, DealProperty, DealUser, Property } from "@elphi/types";
import { useEffect, useMemo } from "react";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";

import { EntityState } from "@reduxjs/toolkit";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import { useDealPropertyRelationHooks } from "../../../../hooks/dealPropertyRelation.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { PropertySliceState } from "../../../../redux/v2/property";
import PathVariables from "../../../../routes/pathVariables";
import { ButtonIconDelete } from "../../../button-icon/ButtonIcon";
import { ElphiListRow } from "../../../elphi-list/ElphiListRow";
import { ElphiTableRow } from "../../../elphi-list/elphiList.types";
import DealUserAssignmentContainer from "../../../los-user/DealUserAssignmentContainer";
import { ItemsMenuCopyWidget } from "../../../party/table/v2/ItemsMenuCopyWidget";
import { ElphiCellType, OrderableTableProps } from "../../../table/table.types";
import { getPropertyAddress } from "../../../task/taskPrint.utils";
import { TableInput } from "../../../utils/tableUtils";
import { DeletePropertyModal } from "../../DeleteProperty";
import {
  actionStyles,
  addressLineTextStyles,
  userAssignmentStyles
} from "../../styles/table";
import { usePropertyTableState } from "./hooks";

export type PropertyTableState = {
  propertyState: PropertySliceState;
  dealPropertyState: EntityState<DealProperty>;
  dealUserState: EntityState<DealUser>;
  dealState: EntityState<Deal>;
};
export const PropertyRow = (
  props: {
    isDisabled?: boolean;
    isActionable?: boolean;
    propertyId: string;
    dealPropertyId?: string;
    index?: string;
    state: PropertyTableState;
  } & OrderableTableProps
) => {
  const { isDisabled, isActionable, state, tableName, isOrderable } = props;
  const { dealState, propertyState } = state;
  const { setSelectedProperty } = usePropertyHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const {
    syncPropertyState,
    syncDealState,
    localPropertyState,
    localDealState,
    propertyOnChange: onChange
  } = usePropertyTableState();

  const dealId =
    props.dealPropertyId &&
    dealPropertyRelationState.entities?.[props.dealPropertyId]?.dealId;
  const { selectedOrgId } = useOrgHooks();
  const fieldsSpecs = getSpecs();
  const propertySpecFields = fieldsSpecs.property?.entitySpecs;
  useEffect(
    () =>
      syncPropertyState({
        shouldSync: !!propertyState.entities?.[props.propertyId],
        state: propertyState.entities?.[props.propertyId],
        statePath: () => {
          return ["properties", props.propertyId];
        }
      }),
    [propertyState.entities?.[props.propertyId]]
  );

  useEffect(() => {
    if (dealId) {
      syncDealState({
        shouldSync: !!dealState.entities?.[dealId],
        state: dealState.entities?.[dealId],
        statePath: () => {
          return ["deals", dealId];
        }
      });
    }
  }, [dealId, dealId && dealState.entities?.[dealId]]);

  const buildPropertyLink = ({ orgId, propertyId }) => {
    return propertyId && orgId
      ? `/${orgId.toString()}${
          PathVariables.PropertyPipeline
        }?property=${propertyId}`
      : undefined;
  };

  const buildDealLink = ({ orgId, dealId }) => {
    return dealId && orgId
      ? `/${orgId.toString()}${PathVariables.WorksheetPath}/${dealId}`
      : undefined;
  };

  const buildRowCells = (rowData: {
    property: Property;
    deal?: Deal;
    index?: string | number;
  }): ElphiTableRow => {
    return {
      cells: [
        {
          index: 0,
          data: (
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="16px" fontStyle={"bold"} align={"center"}>
                {rowData.index?.toString()}
              </Text>
            </Box>
          ),
          type: ElphiCellType.Element,
          maxWidth: "80px",
          minWidth: "80px"
        },
        {
          index: 1,
          data: (
            <Box>
              <Flex justifyContent={"space-evenly"}>
                {isActionable && (
                  <>
                    <Tooltip label="edit property">
                      <Center>
                        <IconButton
                          size="xs"
                          aria-label="edit property"
                          icon={<EditIcon />}
                          onClick={() => {
                            setSelectedProperty(rowData?.property?.id || "");
                          }}
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip
                      label={`go to property: ${getPropertyAddress(
                        rowData?.property
                      )}`}
                    >
                      <Center>
                        <Link
                          href={buildPropertyLink({
                            orgId: selectedOrgId?.toString(),
                            propertyId: rowData?.property?.id
                          })}
                          isExternal
                        >
                          <IconButton
                            bgColor={
                              elphiTheme.components.light.button.navigation
                                .showProperties
                            }
                            size="xs"
                            aria-label={"edit-property-btn"}
                            icon={<ExternalLinkIcon />}
                            isDisabled={!rowData?.property?.id}
                          />
                        </Link>
                      </Center>
                    </Tooltip>
                    <Tooltip
                      label={
                        !rowData?.deal?.id
                          ? "property is not assigned to a deal"
                          : `new tab deal: ${rowData.deal.LoanIdentifier}`
                      }
                    >
                      <Center>
                        <Link
                          href={buildDealLink({
                            orgId: selectedOrgId?.toString(),
                            dealId: rowData?.deal?.id
                          })}
                          isExternal
                        >
                          <IconButton
                            bgColor={
                              elphiTheme.components.light.button.navigation
                                .goToDeal
                            }
                            size="xs"
                            aria-label="edit worksheet"
                            icon={<ExternalLinkIcon />}
                            isDisabled={!rowData?.deal?.id}
                          />
                        </Link>
                      </Center>
                    </Tooltip>
                  </>
                )}
                <Tooltip label="system identifiers">
                  <Center>
                    <ItemsMenuCopyWidget
                      items={[
                        {
                          label: "elphi-property-id",
                          value: rowData?.property?.id
                        },
                        {
                          label: "elphi-deal-id",
                          value: rowData?.deal?.id
                        },
                        {
                          label: "crm-deal-id",
                          value: rowData?.deal?.CRMId
                        },
                        {
                          label: "loan number",
                          value: rowData?.deal?.LoanIdentifier
                        }
                      ]}
                    />
                  </Center>
                </Tooltip>
                {!isDisabled && (
                  <Tooltip label="Delete Property">
                    <Center>
                      <DeletePropertyModal
                        isDisabled
                        buttonFn={ButtonIconDelete}
                        injectedProperty={rowData.property}
                      />
                    </Center>
                  </Tooltip>
                )}
              </Flex>
            </Box>
          ),
          type: ElphiCellType.Element,
          minWidth: actionStyles.minWidth,
          maxWidth: actionStyles.maxWidth
        },
        {
          index: 2,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.AddressLineText}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 3,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.AddressUnitIdentifier}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 4,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.CityName}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 5,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.CountyName}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 6,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.StateName}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 7,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.StateCode}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 8,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.PostalCode}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 9,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.ParcelNumber}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 10,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.PropertyLotIdentifier}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 11,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.Address?.PropertyBlockIdentifier}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 12,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={
                propertySpecFields?.Address?.PropertySubdivisionIdentifier
              }
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 13,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={propertySpecFields?.ConstructionCostAmount}
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 14,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={
                propertySpecFields?.Appraisal?.PropertyValuationAmount
              }
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 15,
          data: (
            <TableInput
              isDisabled={props.isDisabled}
              specFields={
                propertySpecFields?.Appraisal?.SubjectToPropertyValuationAmount
              }
              prefix={["properties", rowData.property.id]}
              state={localPropertyState}
              currentEntity={rowData.property}
              onChange={onChange}
            />
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 16,
          data: (
            <Text align={"center"}>
              {rowData?.deal?.LoanIdentifier ?? "N/A"}
            </Text>
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 17,
          data: (
            <Text align={"center"}>
              {getFocusedData(rowData?.deal?.aggregations?.LoanProgramType) ??
                "N/A"}
            </Text>
          ),
          type: ElphiCellType.Element,
          maxWidth: addressLineTextStyles.maxWidth,
          minWidth: addressLineTextStyles.minWidth
        },
        {
          index: 18,
          data: rowData?.deal?.id ? (
            <Box>
              <DealUserAssignmentContainer
                dealId={rowData.deal.id}
                isDisabled={props.isDisabled}
              />
            </Box>
          ) : (
            <Text w="100%" align="center">
              {"N/A"}
            </Text>
          ),
          type: ElphiCellType.Element,
          maxWidth: userAssignmentStyles.maxWidth,
          minWidth: userAssignmentStyles.minWidth
        }
      ],
      index: props.index,
      minHeight: "53px",
      maxHeight: "53px"
    };
  };

  const rowsMemo = useMemo(() => {
    const deal = dealId ? localDealState?.deals?.[dealId]! : undefined;
    const property = localPropertyState?.properties?.[props.propertyId] || {};
    const rowData = {
      deal: deal,
      property: property,
      index: props.index
    };
    return buildRowCells(rowData) || {};
  }, [
    localDealState,
    localPropertyState,
    dealPropertyRelationState,
    selectedOrgId,
    isDisabled,
    isActionable
  ]);

  return (
    <Box w="100%">
      <ElphiListRow
        tableName={tableName}
        isOrderable={isOrderable}
        cells={rowsMemo.cells}
      />
    </Box>
  );
};
