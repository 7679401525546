import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  IconButton,
  Link,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { removeNulls } from "@elphi/aggregations/src/utils/filter.utils";
import {
  AggregationType,
  Deal,
  DealPartyRelation,
  EntityParty,
  IndividualSSNParty,
  IndividualTINParty
} from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { keyBy } from "lodash";
import { ReactElement, useCallback, useEffect, useMemo } from "react";

import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { getSpecs } from "../../../../forms/schemas/factories/specsFactory";
import useDealHooks from "../../../../hooks/deal.hooks";
import { useDealPartyRelationHooks } from "../../../../hooks/dealpartyrelation.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import { usePartyHooks } from "../../../../hooks/party.hooks";
import { dealApi } from "../../../../redux/v2/deal";
import { PropertySliceState } from "../../../../redux/v2/property";
import PathVariables from "../../../../routes/pathVariables";
import { joinWords } from "../../../../utils/stylingUtils";
import { ElphiListRow } from "../../../elphi-list/ElphiListRow";
import {
  ElphiTableCell,
  ElphiTableRow
} from "../../../elphi-list/elphiList.types";
import { getFocusedAggregationSpecs } from "../../../form-builder/field-specs/utils/aggregation.utils";
import DealUserAssignmentContainer from "../../../los-user/DealUserAssignmentContainer";
import { ItemsMenuCopyWidget } from "../../../party/table/v2/ItemsMenuCopyWidget";
import { ElphiCellType, OrderableTableProps } from "../../../table/table.types";
import { TableInput } from "../../../utils/tableUtils";
import {
  actionStyles,
  closingDateStyles,
  lenderStyles,
  loanProgramStyles,
  milestoneStyles,
  rowIndexStyles
} from "../../styles/table";
import {
  BorrowingEntityV2 as BorrowingEntity,
  Sponsor
} from "../../utils/table.utils";
import { DealLenderCell, DealMilestoneCell } from "./DealMilestoneCell";
import { useDealTableState } from "./hooks";
export const DealRow = (
  props: {
    dealId: string;
    index?: string;
    dealTableState: ReturnType<typeof useDealTableState>;
    onOpen: () => void;
  } & OrderableTableProps
) => {
  const { tableName, isOrderable } = props;
  const [getDealProperties, dealPropertiesResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const { dealState, setDealTableSelectedDealId, navigateToWorksheetV1 } =
    useDealHooks();
  const { partyState } = usePartyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { selectedOrgId } = useOrgHooks();
  const fieldsSpecs = getSpecs();
  const { syncDealState, localDealState, dealOnChange, localPropertyState } =
    props.dealTableState;

  useEffect(
    () =>
      syncDealState({
        shouldSync: !!dealState.entities?.[props.dealId],
        state: dealState.entities?.[props.dealId],
        statePath: () => {
          return ["deals", props.dealId];
        }
      }),
    [dealState.entities?.[props.dealId]]
  );

  useEffect(
    () =>
      syncDealState({
        shouldSync: !!dealState.entities?.[props.dealId],
        state: dealState.entities?.[props.dealId],
        statePath: () => {
          if (props.dealId) {
            return ["deals", props.dealId];
          }
        }
      }),
    [dealState.entities?.[props.dealId]]
  );

  const PropertyText = (props: {
    rowData: {
      dealPartyRelations?: DealPartyRelation[] | undefined;
      parties: (
        | IndividualSSNParty
        | IndividualTINParty
        | EntityParty
        | null
        | undefined
      )[];
      deal?: Deal | undefined;
      index: string | number;
    };
    localPropertyState: PropertySliceState;
  }) => {
    const { rowData, localPropertyState } = props;
    const totalNumberOfProperties = Number(
      getFocusedData(rowData.deal?.aggregations?.TotalNumberOfProperties) ?? 0
    );

    const properties = Object.values(localPropertyState?.entities).filter(
      removeNulls
    );

    const address = properties.find((p) =>
      p?.dealRelation?.includes(rowData.deal?.id ?? "")
    )?.Address;

    if (address) {
      const addressLine = joinWords([
        address.AddressLineText,
        address.AddressUnitIdentifier,
        address.PropertyLotIdentifier,
        address.PropertyBlockIdentifier,
        address.PropertySubdivisionIdentifier
      ]);
      const cityLine = joinWords([
        address.CityName,
        address.StateCode,
        address.PostalCode
      ]);
      const numberOfPropsText =
        totalNumberOfProperties > 1 ? `+${totalNumberOfProperties - 1}` : EMPTY;

      return (
        <Box>
          {addressLine && (
            <Center as="span">
              {addressLine} {!cityLine && numberOfPropsText}
            </Center>
          )}
          {cityLine && (
            <Center as="span">
              {cityLine} {numberOfPropsText}
            </Center>
          )}
        </Box>
      );
    }

    return (
      <Box>
        <Center as="span">{NOT_AVAILABLE}</Center>
      </Box>
    );
  };

  const rowCells = useCallback(
    (rowData: {
      dealPartyRelations?: DealPartyRelation[];
      parties: (
        | IndividualSSNParty
        | IndividualTINParty
        | EntityParty
        | null
        | undefined
      )[];
      deal?: Deal;
      index: string | number;
    }): ElphiTableRow => {
      return {
        cells:
          (rowData.deal && [
            {
              index: 0,
              data: (
                <Box>
                  <Text textAlign={"center"} fontSize="16px" fontStyle="bold">
                    {rowData.index.toString()}
                  </Text>
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: rowIndexStyles.maxWidth,
              minWidth: rowIndexStyles.minWidth
            },
            {
              index: 1,
              data: (
                <Flex pl="7px" pr="7px" justifyContent={"space-between"}>
                  <Tooltip label="edit deal">
                    <Box>
                      <IconButton
                        size="xs"
                        aria-label="edit deal"
                        icon={<EditIcon />}
                        onClick={() => {
                          rowData?.deal?.id &&
                            selectedOrgId &&
                            selectedOrgId?.toString() &&
                            navigateToWorksheetV1(
                              selectedOrgId.toString(),
                              rowData?.deal?.id
                            );
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip label="show properties">
                    <Box pl="5px">
                      <IconButton
                        bgColor="purple.200"
                        size="xs"
                        aria-label="show properties"
                        icon={<ViewIcon />}
                        onClick={() => {
                          if (!!rowData?.deal?.id) {
                            getDealProperties([rowData?.deal?.id], true);
                            setDealTableSelectedDealId(rowData?.deal?.id);
                            props.onOpen();
                          }
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <NavigateToDeal
                    rowData={rowData}
                    selectedOrgId={selectedOrgId?.toString()}
                  >
                    <IconButton
                      bgColor="blue.100"
                      size="xs"
                      aria-label="edit worksheet"
                      icon={<EditIcon />}
                    />
                  </NavigateToDeal>
                  <Tooltip label={"system identifiers"} placement="top">
                    <Box pl="5px">
                      <ItemsMenuCopyWidget
                        items={[
                          {
                            label: "elphi-deal-id",
                            value: rowData?.deal?.id
                          },
                          {
                            label: "crm-deal-id",
                            value: rowData?.deal?.CRMId
                          },
                          {
                            label: "loan number",
                            value: rowData?.deal?.LoanIdentifier
                          }
                        ]}
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              ),
              type: ElphiCellType.Element,
              maxWidth: actionStyles.maxWidth,
              minWidth: actionStyles.minWidth
            },
            {
              index: 2,
              data: (
                <NavigateToDeal
                  rowData={rowData}
                  selectedOrgId={selectedOrgId?.toString()}
                >
                  <Box cursor="pointer">
                    <Text textAlign={"center"} fontSize="16px" fontStyle="bold">
                      {rowData.deal && rowData.deal.LoanIdentifier}
                    </Text>
                  </Box>
                </NavigateToDeal>
              ),
              type: ElphiCellType.Element,
              maxWidth: "200px",
              minWidth: "200px"
            },
            {
              index: 3,
              data: (
                <Box>
                  <Center>
                    <BorrowingEntity
                      currentParties={rowData.parties}
                      dealPartyRelations={rowData.dealPartyRelations}
                    />
                  </Center>
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "200px",
              minWidth: "200px"
            },
            {
              index: 4,
              data: (
                <Box>
                  <Center>
                    <Sponsor
                      currentParties={rowData.parties}
                      dealPartyRelations={{
                        ids:
                          rowData.dealPartyRelations?.map((dpr) => dpr.id) ||
                          [],
                        entities: keyBy(rowData.dealPartyRelations || [], "id")
                      }}
                    />
                  </Center>
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "200px",
              minWidth: "200px"
            },
            {
              index: 5,
              data: (
                <Box>
                  <TableInput
                    isAggregation={AggregationType.ThirdParty}
                    specFields={getFocusedAggregationSpecs({
                      spec: fieldsSpecs.deal?.entitySpecs?.aggregations
                        ?.LoanProgramType,
                      focused:
                        rowData.deal?.aggregations?.LoanProgramType?.focused
                    })}
                    prefix={["deals", rowData.deal.id]}
                    state={localDealState}
                    currentEntity={rowData.deal}
                    onChange={dealOnChange}
                    widthStyles={{
                      minWidth: loanProgramStyles.minWidth,
                      maxWidth: loanProgramStyles.maxWidth
                    }}
                  />
                </Box>
              ),
              type: ElphiCellType.Element,
              minWidth: loanProgramStyles.minWidth,
              maxWidth: loanProgramStyles.maxWidth
            },

            {
              index: 6,
              data: rowData.deal?.id ? (
                <Box pl="15px" pr="15px">
                  <DealUserAssignmentContainer
                    dealId={rowData.deal.id}
                    loanIdentifier={rowData?.deal?.LoanIdentifier}
                  />
                </Box>
              ) : (
                <Box pl="15px" pr="15px">
                  <Text w="100%" align="center">
                    Not Available
                  </Text>
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: "360px",
              minWidth: "360px"
            },
            {
              index: 7,
              data: (
                <Box>
                  <TableInput
                    isAggregation={AggregationType.ThirdParty}
                    specFields={getFocusedAggregationSpecs({
                      spec: fieldsSpecs.deal?.entitySpecs?.aggregations
                        ?.EstimatedClosingDate,
                      focused:
                        rowData.deal?.aggregations?.EstimatedClosingDate
                          ?.focused
                    })}
                    prefix={["deals", rowData.deal.id]}
                    state={localDealState}
                    currentEntity={rowData.deal}
                    onChange={dealOnChange}
                    widthStyles={{
                      minWidth: closingDateStyles.minWidth,
                      maxWidth: closingDateStyles.maxWidth
                    }}
                  />
                </Box>
              ),
              type: ElphiCellType.Element,
              minWidth: "350px",
              maxWidth: "350px"
            },
            {
              index: 8,
              data: (
                <PropertyText
                  rowData={rowData}
                  localPropertyState={localPropertyState}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "200px",
              minWidth: "200px"
            },
            {
              index: 9,
              data: (
                <Box>
                  <DealLenderCell deal={rowData.deal} />
                </Box>
              ),
              type: ElphiCellType.Element,
              maxWidth: lenderStyles.maxWidth,
              minWidth: lenderStyles.minWidth
            },
            {
              index: 10,
              data: (
                <Box>
                  <DealMilestoneCell deal={rowData.deal} />
                </Box>
              ),
              type: ElphiCellType.Element,
              minWidth: milestoneStyles.maxWidth,
              maxWidth: milestoneStyles.maxWidth
            }
          ]) ||
          ([] as ElphiTableCell[]),
        index: props.index,
        minHeight: "53px",
        maxHeight: "53px"
      };
    },
    [props.dealTableState, props.dealId, dealPropertiesResponse, selectedOrgId]
  );
  const cellsMemo = useMemo(() => {
    const dealPartyRelations: DealPartyRelation[] =
      !!dealPartyRelationState?.entities
        ? Object.values(dealPartyRelationState?.entities)
            ?.filter((dpr) => dpr?.dealId === props.dealId)
            .filter(removeNulls)
        : [];

    const rowData =
      rowCells({
        deal: dealState.entities[props.dealId],
        dealPartyRelations: dealPartyRelations,
        parties:
          dealPartyRelations.map((dpr) =>
            dpr?.partyId ? partyState.entities[dpr?.partyId!] : null
          ) || [],
        index: props.index!
      }) || [];
    return rowData;
  }, [dealPartyRelationState, partyState, dealState, localDealState]);

  return (
    <ElphiListRow
      tableName={tableName}
      isOrderable={isOrderable}
      cells={cellsMemo.cells}
    />
  );
};

export const NavigateToDeal = (props: {
  children: ReactElement;
  selectedOrgId: EntityId | undefined;
  rowData?: {
    deal?: Partial<Deal>;
  };
}) => {
  const { rowData, selectedOrgId, children } = props;
  return (
    <Tooltip
      label={rowData?.deal && `new tab deal:${rowData?.deal?.LoanIdentifier}`}
    >
      <Box pl="5px">
        <Link
          href={
            rowData?.deal?.id &&
            `../../${selectedOrgId?.toString()}${PathVariables.WorksheetPath}/${
              rowData?.deal?.id
            }`
          }
          isExternal
        >
          {children}
        </Link>
      </Box>
    </Tooltip>
  );
};
