import {
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { limaSpecs } from "../spec-files/limaFieldsSpecs";

export const overridePartyValidationSettingsIndicator = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.entityPartyFieldSpecs
      .OverridePartyValidationSettingsIndicator?.fieldKey,
  validation: singleSelectValidation
});

export const crmId = buildInput({
  fieldKey: limaSpecs.party?.specsParts?.basePartyFieldSpecs.CRMId?.fieldKey,
  validation: stringValidation
});

export const individualPaymentTokenId = buildInput({
  fieldKey:
    limaSpecs.party?.specsParts?.individualPartyFieldSpecs.PaymentTokenId
      ?.fieldKey,
  validation: stringValidation
});

export const individualCrmId = buildInput({
  fieldKey: limaSpecs.party?.specsParts?.basePartyFieldSpecs.CRMId?.fieldKey,
  validation: stringValidation
});
